import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AddOutlined } from "@mui/icons-material"
import { routeUrls } from "../../../routes/routeUrls"
import AppButton from "../../../components/AppButton/AppButton"
import { useGetAllUsersQuery } from "../../../api/network/userApiService"
import DisplayTable from "../../../components/DisplayTable/DisplayTable"
import useAppPagination from "../../../hooks/useAppPagination"
import { getTotalPages } from "../../../utils"
import { TUserResponse } from "../../../api/types/User"
import useUserRole from "../../../hooks/useUserRole"
import { ProjectUserRole, UserRole } from "../../../api/types/Auth"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import TableCellText from "../../../components/DisplayTable/TableCellText"
import ViewDetailButton from "../../../components/DisplayTable/ViewDetailButton"
import AppDropdownFilter from "../../../components/AppDropdownFilter/AppDropdownFilter"
import useCompaniesListOption from "../../../hooks/useCompaniesListOption"
import AppAutocompleteFilter from "../../../components/AppDropdownFilter/AppAutocompleteFilter"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../../api/store/store"
import AppNavBar from "../../../components/AppNavBar/AppNavBar"
import InvitedUserList from "./InvitedUserList"

const tableHeaders = [
	{ name: "Name", sortable: false },
	{ name: "Email", sortable: false },
	{ name: "Company", sortable: false },
	{ name: "Joined On", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "", sortable: false },
]

const UsersList: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("translation", { keyPrefix: "usersList" })
	const [statusFilter, setStatusFilter] = useState<string>()
	const [companyFilter, setCompanyFilter] = useState<number>()
	const [lockedFilter, setLockedFilter] = useState<string>()
	const [viewInvitedUsers, setViewInvitedUsers] = useState<boolean>(false)
	const [invitedUsersList, setInvitedUsersList] = useState<any>([])

	const appUserRole = useUserRole()
	const { currentProject } = useSelector(
		(state: RootReduxState) => state.userProjectData,
	)

	useLayoutEffect(() => {
		if (
			appUserRole === UserRole.User &&
			(!currentProject ||
				currentProject.role !== ProjectUserRole.SuperIntendent)
		) {
			navigate(routeUrls.dashboard)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appUserRole, currentProject])

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()
	const [queryArgs, setQueryArgs] = useState({
		page_size: pageRowsCount,
		page: currentPage,
		is_active: statusFilter,
		company: companyFilter,
		is_account_locked: lockedFilter,
		project:
			appUserRole === UserRole.User
				? currentProject?.project_id || 0
				: undefined,
	})
	const { data, isFetching, refetch } = useGetAllUsersQuery({
		page_size: pageRowsCount,
		page: currentPage,
		is_active: statusFilter,
		company: companyFilter,
		is_account_locked: lockedFilter,
		project:
			appUserRole === UserRole.User
				? currentProject?.project_id || 0
				: undefined,
	})

	const { isFetching: isCompaniesFetching, companiesOptions } =
		useCompaniesListOption()

	const results = data?.results ?? []

	const userStatusOptions = useMemo(() => {
		return [
			// { id: t("all"), label: t("all") },
			{ id: "true", label: t("active") },
			{ id: "false", label: t("suspended") },
			// { id: t("removed"), label: t("removed") },
		]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const navigateUserDetail = (userId: number) => () => {
		navigate(routeUrls.users.profile(userId.toString()))
	}

	const tableColumns = useMemo(
		() => [
			"name",
			"email",
			"company_name",
			(data: TUserResponse) => {
				return formatDateWithMonthName(data.date_joined)
			},
			(data: TUserResponse) => {
				return data.is_active ? (
					<TableCellText color="success">Active</TableCellText>
				) : (
					<TableCellText color="error">Deactivated</TableCellText>
				)
			},
			(data: TUserResponse) => {
				return (
					appUserRole !== UserRole.User && (
						<ViewDetailButton onClick={navigateUserDetail(data.id)} />
					)
				)
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const onAddUserClick = () => {
		navigate(routeUrls.users.add)
	}

	const handleLockedUserFilterChange = (locked_status: any) => {
		console.log(locked_status)
		if (locked_status === "Locked") {
			setLockedFilter("true")
		} else {
			setLockedFilter("false")
		}
	}

	const handleCompanyFilterChange = (company: any) => {
		setCompanyFilter(company ? company.id : company)
	}

	const handleUserStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const handleClickLabel = (label: string) => {
		if (label === "Invited") {
			// handleInvitedUserFilterChange()
			setViewInvitedUsers(true)
		} else if (label === "Locked") {
			handleLockedUserFilterChange(label)
			setViewInvitedUsers(false)
		} else if (label === "Existing") {
			// window.location.reload()
			setViewInvitedUsers(false)
			setLockedFilter("")
			setStatusFilter("")
			setCompanyFilter(undefined)
			setQueryArgs({
				...queryArgs,
				page_size: 10,
				page: 1,
			})
			// refetch()
		} else {
			setViewInvitedUsers(false)
		}
	}

	useEffect(() => {
		refetch()
	},[])

	return (
		<div className="users-list-section">
			<div className="heading-wrapper">
				<Typography className="title">{t("users")}</Typography>
				{appUserRole !== UserRole.User && (
					<AppButton
						btnSize="medium"
						onClick={onAddUserClick}
						endIcon={<AddOutlined />}
					>
						{t("add_a_user")}
					</AppButton>
				)}
			</div>
			<div className="search-and-filters-container">
				<div className="search-and-filters">
					{appUserRole === UserRole.SuperAdmin && (
						<>
							<AppAutocompleteFilter
								disabled={isCompaniesFetching}
								label={t("companies")}
								placeholder={t("search_here")}
								options={companiesOptions}
								value={companyFilter}
								onChange={setCompanyFilter}
							/>
							<AppDropdownFilter
								placeholder={"Company"}
								value={companyFilter}
								menuItems={companiesOptions}
								onChange={handleCompanyFilterChange}
							/>
						</>
					)}
					<AppDropdownFilter
						placeholder={t("status")}
						value={statusFilter}
						menuItems={userStatusOptions}
						onChange={handleUserStatusFilterChange}
					/>
				</div>
				{appUserRole === UserRole.SuperAdmin &&
				<Box width={330} display={"flex"} justifyContent={"end"}>
					<AppNavBar
						options={["Existing", "Invited", "Locked"]}
						handleClickLabel={handleClickLabel}
						defaultSelected="Existing"
						marginBottom={false}
					/>
				</Box>}
			</div>

			{viewInvitedUsers ? (
				<InvitedUserList InvitedUserList={invitedUsersList} />
			) : (
				<DisplayTable
					isFetching={isFetching}
					tableHeaders={tableHeaders}
					tableColumns={tableColumns}
					results={results}
					pageRowsCount={pageRowsCount}
					onPageCountChange={handlePageCountChange}
					totalPages={getTotalPages(data?.count, data?.page_size)}
					currentPage={currentPage}
					onPageChange={handlePageChange}
					totalPageCount={data?.count}
				/>
			)}
		</div>
	)
}

export default UsersList
