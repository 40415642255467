import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
	Box,
	IconButton,
	InputAdornment,
	Link,
	Typography,
} from "@mui/material"
import { useFormik } from "formik"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import { routeUrls } from "../../routes/routeUrls"
import AppButton from "../../components/AppButton/AppButton"
import {
	ILoginFields,
	loginFormInitialValues,
	loginValidationSchema,
} from "./validation"
import "./Login.scss"
import AppFormField from "../../components/AppFormField/AppFormField"
import { Svgs } from "../../assets/svgs"
import { useLoginMutation } from "../../api/network/authApiSercvice"
import { LoginResponseSuccess, UserRole } from "../../api/types/Auth"
import { localStorage } from "../../utils/localstorage"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { clearAuthData, updateAuthData } from "../../api/slices/AuthSlice"
import useAuthData from "../../hooks/useAuthData"
import { clearAllApiCache } from "../../utils/authUtils"

const LoginPage = () => {
	const [showPassword, setShowPassword] = useState(false)
	const { t } = useTranslation("translation", { keyPrefix: "loginPage" })

	const [loginApiTrigger, { isLoading }] = useLoginMutation()

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const authData = useAuthData()
	const location = useLocation()
	useEffect(() => {
		localStorage.getUserData().then((userData) => {
			if (userData) {
				navigateUserBasedOnRole(userData)
			} else if (authData?.token) {
				// clearing auth data if the data is not in storage but in store
				dispatch(clearAuthData())
				setTimeout(() => clearAllApiCache(dispatch))
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const navigateUserBasedOnRole = (userData: LoginResponseSuccess) => {
		if (userData?.token) {
			// navigating the user to dashboard
			if (
				userData.user.user_role === null &&
				userData.user.is_profile_setup === false
			) {
				navigate(routeUrls.profileSetup, { state: { userData } })
			} else if (
				userData.user.user_role?.role === UserRole.CompanyAdmin &&
				userData.user.is_profile_setup === false
			) {
				navigate(routeUrls.profileSetup, { state: { userData } })
			} else {
				navigate(
					routeUrls.dashboard,
					// userData.user.user_role?.role === UserRole.SuperAdmin ||
					// 	userData.user.user_role?.role === UserRole.CompanyAdmin
					// 	? routeUrls.dashboard
					// 	: routeUrls.userDashboard,
				)
			}
		}
	}

	const handleLogin = (values: ILoginFields) => {
		const { email, password } = values
		loginApiTrigger({
			email: email,
			password: password,
		})
			.unwrap()
			.then((data) => {
				const result = data as LoginResponseSuccess
				if (result.token) {
					localStorage.saveUserData(result).then(() => {
						dispatch(updateAuthData(result))
						navigateUserBasedOnRole(result)
					})
				}
			})
			.catch((err) => {
				console.log("Error while login", err)
			})
	}

	const { values, errors, touched, handleChange,setFieldValue, handleBlur, handleSubmit } =
		useFormik({
			initialValues: loginFormInitialValues,
			onSubmit: handleLogin,
			validationSchema: loginValidationSchema,
		})

	const toggleShowPassword = () => {
		setShowPassword((val) => !val)
	}

	useEffect(()=>{
		if(location?.state?.email != null){
			setFieldValue('email',location?.state?.email);
		}
	},[])

	return (
		<AuthLayout>
			<div className="login-form-container">
				<form onSubmit={handleSubmit}>
					<Typography className="title">{t("login_heading")}</Typography>
					<div className="fields-container">
						<AppFormField
							label={t("email_address")}
							isRequired
							highlightLabelIfDataIsValid
							name="email"
							type="email"
							placeholder={t("enter_email")}
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.email}
							errorMsg={errors.email ? t(errors.email) : ""}
						/>
						<AppFormField
							label={t("password")}
							isRequired
							highlightLabelIfDataIsValid
							name="password"
							type={showPassword ? "text" : "password"}
							placeholder={t("enter_password")}
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.password}
							errorMsg={errors.password ? t(errors.password) : ""}
							inputProps={{
								slotProps: {
									input: {
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={toggleShowPassword}
												>
													{showPassword ? (
														<Svgs.EyeOutlineBlue />
													) : (
														<Svgs.EyeOutline />
													)}
												</IconButton>
											</InputAdornment>
										),
									},
								},
							}}
						/>
						<Box display="flex" alignItems="center" justifyContent="flex-end">
							{/* <FormControlLabel
								className="remember-me-checkbox"
								control={<Checkbox color="secondary" />}
								label={t("remember_me")}
							/> */}
							<Link
								color="secondary"
								href={routeUrls.forgotPassword}
								variant="body2"
								sx={{
									fontSize: "14px",
									lineHeight: "17.09px",
									fontWeight: "500",
								}}
								underline="none"
							>
								{t("forgot_password")}
							</Link>
						</Box>
						<AppButton disabled={isLoading} type="submit" btnSize="large">
							{t("login_btn")}
						</AppButton>
					</div>
				</form>
			</div>
		</AuthLayout>
	)
}

export default LoginPage
