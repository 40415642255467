import { Box, Grid2 } from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import { IAddActivitySectionFormProps } from "./validation"

export default function ActivityOtherDetails({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	disabled,
	handleBlur,
}: IAddActivitySectionFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				marginBottom: 4,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 className="title">{t("other_details")}</Grid2>
			<Grid2 mb={1}>
				<AppFormField
					label={t("activity_notes")}
					name={"activity_notes"}
					placeholder={t("activity_notes")}
					value={values.activity_notes}
					disabled={disabled}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.activity_notes}
					errorMsg={errors.activity_notes}
				/>
			</Grid2>
			<Box display={"flex"} justifyContent={"space-between"} gap={2}>
				{/* <Grid2 width={"100%"}>
					<AppAutocompleteFormField
						label={t("activity_resources")}
						name={"activity_resources"}
						placeholder={t("activity_resources")}
						value={""}
						options={[
							{ id: 1, label: "Trade1" },
							{ id: 2, label: "Trade2" },
						]}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("activity_cost")}
						name={"activity_cost"}
						placeholder={"activity_cost"}
						value={""}
						// onChange={handleMessageChange}
						// onBlur={handleBlur}
						// touched={touched.message}
						// errorMsg=
						// {errors.message ? "Message is required" : ""}
					/>
				</Grid2> */}
			</Box>
		</Box>
	)
}
