import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Grid2, Typography } from "@mui/material"
import AppButton from "../../components/AppButton/AppButton"
import "./Company.scss"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useFormik } from "formik"
import {
	addCompanyFormInitialValues,
	addCompanyValidationSchema,
	IAddCompanydFields,
} from "./validations"
import { useTranslation } from "react-i18next"
import "./Company.scss"
import {
	useChangeCompanyStatusMutation,
	useEditCompanyMutation,
	useGetCompanyDetailsQuery,
} from "../../api/network/companyApiService"
import { useNavigate, useParams } from "react-router-dom"
import { AppFileField } from "../../components/AppFileField/AppFileField"
import { routeUrls } from "../../routes/routeUrls"
import { toast } from "react-toastify"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import useAuthData from "../../hooks/useAuthData"
import AppLoader from "../../components/AppLoader/AppLoader"
import useUserRole from "../../hooks/useUserRole"
import { UserRole } from "../../api/types/Auth"
import DeactivateUser from "../AdminPages/Users/DeactivateUser"

interface CompanyDetailProps {
	isMyCompany?: boolean
}

const CompanyDetail: React.FC<CompanyDetailProps> = ({ isMyCompany }) => {
	const navigate = useNavigate()
	const { t: tMain } = useTranslation()
	const { t } = useTranslation("translation", { keyPrefix: "company" })
	const { t: tAdd } = useTranslation("translation", {
		keyPrefix: "company.addCompany",
	})
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "company.errors",
	})
	const { companyId: companyIdParam } = useParams<{ companyId: string }>()
	const [editing, setEditing] = useState(false)
	const [selectedFile, setSelectedFile] = useState<string | null>()

	const appUserRole = useUserRole()
	const authData = useAuthData()

	const companyId = useMemo(() => {
		return isMyCompany ? authData?.user.company_id : companyIdParam
	}, [isMyCompany, authData, companyIdParam])

	const { data, isFetching, refetch } = useGetCompanyDetailsQuery(
		Number(companyId),
	)
	console.log("data", data)
	const [companyStatusHandler] = useChangeCompanyStatusMutation()
	const [editCompanyApiTrigger] = useEditCompanyMutation()

	const handleSave = (values: IAddCompanydFields) => {
		// create form data
		const formData = new FormData()
		formData.append("company_id", values.companyId)
		formData.append("name", values.companyName)
		formData.append("address", values.companyAddress)
		formData.append("email", values.email)
		formData.append("phone", values.phoneNumber)
		if (selectedFile) {
			formData.append("logo", values?.logo as File)
		}

		// Api call to update company
		editCompanyApiTrigger({
			id: data?.id ?? 0,
			formData,
		})
			.unwrap()
			.then((response) => {
				if (response) {
					setEditing(false)
					toast.success("Company added successfully")
				}
			})
			.catch((error) => {
				// toast.error("Error while adding company")
				console.log("error", error)
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: addCompanyFormInitialValues,
		onSubmit: handleSave,
		validationSchema: addCompanyValidationSchema(tErrors),
	})
	console.log("values@", values)
	const resetData = useCallback(() => {
		resetForm()
		if (data) {
			setFieldValue("companyId", data.company_id)
			setFieldValue("companyName", data.name)
			setFieldValue("companyAddress", data.address)
			setFieldValue("email", data.email)
			setFieldValue("phoneNumber", data.phone)
			setFieldValue("logo", data.logo)

			// Clear state data
			setSelectedFile(null)
		}
	}, [data, resetForm, setFieldValue])

	useEffect(() => {
		resetData()
	}, [data, resetData, setFieldValue])

	const onEditClick = () => setEditing(true)

	const onCancelClick = () => {
		if (editing) {
			setEditing(false)
			resetData()
		} else {
			handleBackClick()
		}
	}

	const handleBackClick = () => navigate(routeUrls.dashboardChild.company)

	const handleUserStatus = () => {
		companyStatusHandler({ id: String(companyId) })
			.unwrap()
			.then((response) => {
				toast.success("Company status updated successfully")
				refetch()
			})
			.catch((error) => {
				toast.error(error.data?.detail || "Something went wrong")
			})
	}

	/**
	 * Read the selected file and set it to the form field
	 */
	const onImageSelected = (file: File) => {
		const reader = new FileReader()
		reader.onload = () => {
			setSelectedFile(reader.result as string)
			setFieldValue("logo", file)
		}
		reader.readAsDataURL(file)
	}

	const renderDetailNavBar = () => {
		const isSuperAdminCompanyDetailPage =
			appUserRole === UserRole.SuperAdmin && !isMyCompany
		return (
			(appUserRole === UserRole.SuperAdmin ||
				appUserRole === UserRole.CompanyAdmin) && (
				<>
					<DetailNavBar
						title={values.companyName}
						subTitle={values.companyId}
						showBackButton={isSuperAdminCompanyDetailPage}
						onBackPress={handleBackClick}
						rightView={
							<Box display="flex" gap="16px" alignItems="center">
								{editing && (
									<AppButton
										variant="outlined"
										className="cancel-button"
										onClick={onCancelClick}
									>
										{tMain("cancel")}
									</AppButton>
								)}
								{data?.is_active ? (
						<Grid2 mb={1}>
							{/* <Svgs.ActiveUserIcon/> */}
							<div
								style={{ color: "#54AE2A", fontSize: "16px", fontWeight: 700 }}
							>
								Active
							</div>
						</Grid2>
					) : (
						<Grid2>
							{/* <Svgs.InactiveUserIcon/> */}
							<div
								style={{ color: "#e72222", fontSize: "16px", fontWeight: 700 }}
							>
								Inactive
							</div>
						</Grid2>
					)}
								<DeactivateUser
									handleUserStatus={handleUserStatus}
									userStatus={(data && data.is_active) || false}
									role_type="Company"
								/>

								<AppButton
									sx={{
										marginLeft: 2,
										minWidth: "96px",
									}}
									style={{
										color: "white",
									}}
									type="submit"
									btnSize="medium"
									color={editing ? "success" : "primary"}
									onClick={() => (editing ? handleSubmit() : onEditClick())}
								>
									{editing ? tMain("save") : t("edit_company")}
								</AppButton>
							</Box>
						}
					/>
				</>
			)
		)
	}

	if (isFetching) {
		return <AppLoader open />
	}

	return (
		<div className="detail-container">
			<Box sx={{ padding: 2 }}>
				{renderDetailNavBar()}
				<Grid2 size={12} container spacing={4}>
					<Grid2
						sx={{
							bgcolor: "white",
							border: "2px solid #fff",
							borderRadius: 8,
							boxShadow: 24,
							padding: 4,
							marginTop: 4,
						}}
						size={6}
						spacing={2}
					>
						<div className="field-wrapper">
							<AppFormField
								label={t("id")}
								name="companyId"
								placeholder={tAdd("add_id")}
								isRequired
								disabled={true}
								value={values.companyId}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.companyId}
								errorMsg={errors.companyId ? t(errors.companyId) : ""}
							/>
						</div>
						<div className="field-wrapper">
							<AppFormField
								label={t("name")}
								name="companyName"
								placeholder={tAdd("add_name")}
								isRequired
								disabled={!editing}
								value={values.companyName}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.companyName}
								errorMsg={errors.companyName ? t(errors.companyName) : ""}
							/>
						</div>
						<div className="field-wrapper">
							<AppFormField
								label={t("address")}
								name="companyAddress"
								placeholder={tAdd("add_address")}
								// isRequired
								disabled={!editing}
								value={values.companyAddress}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.companyAddress}
								// errorMsg={errors.companyAddress ? t(errors.companyAddress) : ""}
							/>
						</div>
						<div className="field-wrapper">
							<AppFormField
								label={t("email")}
								name="email"
								placeholder={tAdd("add_email")}
								isRequired
								disabled={!editing}
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.email}
								errorMsg={errors.email ? t(errors.email) : ""}
							/>
						</div>
						<div className="field-wrapper">
							<AppFormField
								label={t("phone_number")}
								name="phoneNumber"
								placeholder={tAdd("add_phone_number")}
								isRequired
								disabled={!editing}
								value={values.phoneNumber}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.phoneNumber}
								errorMsg={errors.phoneNumber ? errors.phoneNumber : ""}
							/>
						</div>
					</Grid2>
					<Grid2
						sx={{
							bgcolor: "white",
							border: "2px solid #fff",
							borderRadius: 8,
							boxShadow: 24,
							padding: 4,
							marginTop: 4,
						}}
						size={6}
						container
						spacing={2}
					>
						<Grid2 size={12}>
							<Typography className="title">Logo</Typography>
							<Box
								sx={{
									border: "1px dashed #424242",
									borderRadius: "8px",
									width: "100%",
									aspectRatio: "1",
									marginTop: 3,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{values?.logo ? (
									<img
										src={selectedFile ? selectedFile : (values.logo as string)}
										alt="logo"
										className="image"
									/>
								) : (
									<Typography
										sx={{
											textAlign: "center",
											fontSize: "50px",
											fontWeight: "400",
											alignSelf: "center",
											paddingX: 4,
										}}
									>
										{t("no_logo")}
									</Typography>
								)}
							</Box>
						</Grid2>
						<Grid2 size={12}>
							<AppFileField
								label={t("logo")}
								id="logo"
								disabled={!editing}
								onChange={(file) => onImageSelected(file)}
								isRequired
								value={values.logo as File}
								fileName={values.logo instanceof File ? values?.logo?.name : ""}
								error={errors.logo ?? ""}
							/>
						</Grid2>
					</Grid2>
				</Grid2>
			</Box>
		</div>
	)
}

export default CompanyDetail
