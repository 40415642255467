// xerApiService

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"
import { getUserData } from "../../utils/localstorage"
import { handleAuthError } from "./errorHandler"
import { API_METHODS } from "../../constants/constants"

export const XerAPIs = createApi({
	reducerPath: "XerAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			const userData = await getUserData()
			headers.set("Authorization", `Token ${userData?.token}`)
			return headers
		},
	}),
	endpoints: (builder) => ({
		uploadXerFile: builder.mutation<any, FormData>({
			query: (params: FormData) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPLOAD_XER_FILE,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
	}),
})

export const { useUploadXerFileMutation } = XerAPIs
