import * as Yup from "yup"

export interface IResetPasswordFields {
	password: string
	confirmPassword: string
}

export const initialResetPasswordValues: IResetPasswordFields = {
	password: "",
	confirmPassword: "",
}

export const resetPasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.required("errors.password_required")
		.test("complexity", "errors.invalid_password", (value) => {
			if (!value) return false // Don't validate if value is empty (handled by required)
			const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{14,}$/
			return regex.test(value)
		}),
	confirmPassword: Yup.string().test(
		"confirm password",
		"errors.password_not_match",
		function (value) {
			return value === this.parent.password
		},
	),
})
