import { Typography } from "@mui/material"
import { Item } from "../../pages/Swimlanes/SwimlaneData"
import "./Card.scss"

const LargeCard = ({ title, subtitle, type, status }: Item) => {
	return (
		<div className={`card-container ${status}`}>
			<Typography fontSize={18} className="card-title">
				{title}
			</Typography>
			<Typography  fontSize={16} variant="subtitle1" className="card-subtitle">
				{subtitle}
			</Typography>
			<Typography variant="body2" className={`card-type ${type}`}>
				{type}
			</Typography>
		</div>
	)
}

export default LargeCard
