import { store } from "../api/store/store"
import { LoginResponseSuccess, UserRole } from "../api/types/Auth"
import { routeUserPermission } from "../routes/routeUrls"

export const parseUserRole = (authData: LoginResponseSuccess | null) => {
	if (authData?.user?.user_role?.role) {
		return authData?.user?.user_role?.role
	} else if (authData?.user?.user_role === null) {
		return UserRole.User
	} else {
		return UserRole.User
	}
}

export const hasAccessToPage = (url: string, data?: any) => {
	const authData: any = data || store.getState().authData
	if (authData !== null) {
		const userRole = parseUserRole(authData as LoginResponseSuccess)
		return routeUserPermission[url]?.includes?.(userRole)
	}
	return false
}
