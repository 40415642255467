import { Box, Grid2, Menu, MenuItem } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import ActivityGeneralDetailsForm from "./ActivityGeneralDetailsForm"
import { format as dateFnsFormat } from "date-fns"
import ActivityDateTime from "./ActivityDateTime"
import ActivityDays from "./ActivityDays"
import ActivityRelation from "./ActivityRelation"
import ActivityOtherDetails from "./ActivityOtherDetails"
import { useFormik } from "formik"
import {
	AddActivityInitialValue,
	AddActivityValidationSchema,
	IActivity,
} from "./validation"
import {
	useGetAllSubActivitiesByActivityQuery,
	useLazyGetActivityByIdQuery,
	useUpdateActivityMutation,
} from "../../api/network/projectApiService"
import { ActivityDetail, UpdateActivityPayload } from "../../api/types/Project"
import { toast } from "react-toastify"
import { routeUrls } from "../../routes/routeUrls"
import { Svgs } from "../../assets/svgs"
import SubActivitiesList from "./SubActivitiesList"
import AppLoader from "../../components/AppLoader/AppLoader"
import {
	activityStatusArray,
	getActivityStatusColor,
	getActivityStatusLabel,
} from "../../utils"
import { ExpandMoreOutlined } from "@mui/icons-material"

export default function ActivityDetailsPage() {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const [isEditing, setIsEditing] = useState(false)

	const navigate = useNavigate()
	const { id: id = "" } = useParams()
const { data: subActivities } =useGetAllSubActivitiesByActivityQuery(id)
	const [activityData, setActivityData] = useState<ActivityDetail | null>()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const activityStatusMenuOpen = Boolean(anchorEl)

	const [getActivityByIdApiTrigger, { isFetching: isFetchingActivity }] =
		useLazyGetActivityByIdQuery()

	const [updateActivityApiTrigger, { isLoading: isSavingActivity }] =
		useUpdateActivityMutation()

	const projectId = 52

	const fetchActivity = () => {
		getActivityByIdApiTrigger(id)
			.unwrap()
			.then((res) => {
				console.log("Activity data", res)
				setActivityData(res)
				setValues({
					id: res.id,
					activity_id: res.activity_id,
					activity_type: res.activity_type,
					activity_status: res.activity_status,
					activity_name: res.activity_name,
					wbs_code: res.wbs_code,
					wbs_name: res.wbs_name,
					planned_start_date: res.planned_start_date,
					planned_finish_date: res.planned_finish_date,
					actual_start_date: res.actual_start_date,
					actual_finish_date: res.actual_finish_date,
					original_duration: res.original_duration,
					at_completion_duration: res.at_completion_duration,
					assignee: res.assignee.map((a) => a.id),
					predecessors: res.predecessors,
					trade_responsible: res.trade_responsible,
					activity_notes: res.notes,
					activity_resources: res.resources,
					activity_cost: res.costs,
					calendar_working_days: res.calendar?.working_days,
					notes: res.notes,
				})
			})
			.catch((err) => {
				console.log("Activity error", err)
				if (err.status === 404) {
					toast.error(t("activity_not_found"))
					navigate(routeUrls.activity.list)
				}
			})
	}

	useEffect(() => {
		fetchActivity()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const handleUpdateActivity = (_value: IActivity) => {
		const activityPayload: UpdateActivityPayload = {
			id: String(id),
			project: projectId,
			activity_type: _value.activity_type,
			activity_name: _value.activity_name,
			wbs_code: _value.wbs_code,
			wbs_name: _value.wbs_name,
			planned_start_date: dateFnsFormat(
				new Date(_value.planned_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			planned_finish_date: dateFnsFormat(
				new Date(_value.planned_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			original_duration: _value.original_duration,
			at_completion_duration: _value.at_completion_duration,
			assignee: _value.assignee,
			calendar: {
				working_days: _value.calendar_working_days,
			},
			predecessors: [],
			notes: _value.activity_notes,
		}
		if (_value.actual_start_date) {
			activityPayload.actual_start_date = dateFnsFormat(
				new Date(_value.actual_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.actual_finish_date) {
			activityPayload.actual_finish_date = dateFnsFormat(
				new Date(_value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.predecessors.length > 0) {
			activityPayload.predecessors = _value.predecessors.map((predecessor) => {
				return {
					predecessor: predecessor.id,
					relationship_type: predecessor.relationship_type,
					lag_days: predecessor.lag_days || 0,
				}
			})
		}
		updateActivityApiTrigger(activityPayload)
			.unwrap()
			.then((res) => {
				console.log("Activity updated", res)
				toast.success(t("activity_updated_successfully"))
				setIsEditing(false)
			})
			.catch((err) => {
				console.log("Activity update error", err)
				toast.error(t("activity_update_error"))
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		setValues,
	} = useFormik({
		initialValues: {
			id: Number(id || 0),
			activity_id: "",
			activity_status: "",
			...AddActivityInitialValue,
		},
		onSubmit: handleUpdateActivity,
		validationSchema: AddActivityValidationSchema,
	})

	const handleEdit = () => {
		setIsEditing(true)
	}

	const cancelEdit = () => {
		setIsEditing(false)
	}

	const activityStatusList = useMemo(() => {
		return activityStatusArray.filter(
			(status) => status.status_code !== values.activity_status,
		)
	}, [values.activity_status])

	const handleActivityStatusMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget)
	}
	const handleActivityStatusMenuClose = () => {
		setAnchorEl(null)
	}

	const handleActivityStatusMenuChange = (status: string) => () => {
		const _value = values
		const activityPayload: UpdateActivityPayload = {
			id: String(id),
			project: projectId,
			activity_type: _value.activity_type,
			activity_name: _value.activity_name,
			wbs_code: _value.wbs_code,
			wbs_name: _value.wbs_name,
			planned_start_date: dateFnsFormat(
				new Date(_value.planned_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			planned_finish_date: dateFnsFormat(
				new Date(_value.planned_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			),
			original_duration: _value.original_duration,
			at_completion_duration: _value.at_completion_duration,
			assignee: _value.assignee,
			calendar: {
				working_days: _value.calendar_working_days,
			},
			predecessors: [],
			notes: _value.activity_notes,
		}
		if (_value.actual_start_date) {
			activityPayload.actual_start_date = dateFnsFormat(
				new Date(_value.actual_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.actual_finish_date) {
			activityPayload.actual_finish_date = dateFnsFormat(
				new Date(_value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.predecessors.length > 0) {
			activityPayload.predecessors = _value.predecessors.map((predecessor) => {
				return {
					predecessor: predecessor.id,
					relationship_type: predecessor.relationship_type,
					lag_days: predecessor.lag_days || 0,
				}
			})
		}
		updateActivityApiTrigger({
			...activityPayload,
			activity_status: status,
		})
			.unwrap()
			.then(() => {
				toast.success(t("status_updated_success"))
				setFieldValue("activity_status", status)
			})
			.catch((err) => {
				console.log("Error while updating Activity status", err)
				toast.error(t("status_updated_error"))
			})
		handleActivityStatusMenuClose()
	}

	const renderActivityStatusDropdown = () => {
		const _color = getActivityStatusColor(values?.activity_status)
		return (
			<>
				<AppButton
					onClick={handleActivityStatusMenuOpen}
					variant="text"
					endIcon={<ExpandMoreOutlined />}
					sx={{
						color: _color,
					}}
					startIcon={<Svgs.ProjectStatusLogo color={_color} />}
				>
					{getActivityStatusLabel(values.activity_status)}
				</AppButton>
				<Menu
					anchorEl={anchorEl}
					open={activityStatusMenuOpen}
					onClose={handleActivityStatusMenuClose}
				>
					{activityStatusList.map((m) => (
						<MenuItem
							key={m.status_code}
							onClick={handleActivityStatusMenuChange(m.status_code)}
						>
							{m.status}
						</MenuItem>
					))}
				</Menu>
			</>
		)
	}

	const headerButtons = useMemo(() => {
		return (
			<>
				<AppButton
					sx={{
						display: isEditing ? "block" : "none",
					}}
					btnSize="medium"
					color="error"
					variant="outlined"
					disabled={isSavingActivity}
					onClick={cancelEdit}
				>
					{t("cancel")}
				</AppButton>
				<AppButton
					sx={{
						display: isEditing ? "block" : "none",
						color: "white",
					}}
					btnSize="medium"
					disabled={isSavingActivity}
					type="submit"
					color="success"
				>
					{t("save")}
				</AppButton>
				<AppButton
					sx={{
						display: isEditing ? "none" : "block",
					}}
					btnSize="medium"
					disabled={isSavingActivity}
					onClick={handleEdit}
				>
					{t("edit_activity")}
				</AppButton>
			</>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing, isSavingActivity])

	if (isFetchingActivity || !activityData) {
		return <AppLoader open />
	}

	return (
		<Box className="activity-form-container">
			<form onSubmit={handleSubmit}>
				<DetailNavBar
					title={"Excavation of Building Site"}
					showBackButton={true}
					onBackPress={() => navigate(routeUrls.activity?.list)}
					rightView={
						<Grid2 display={"flex"} gap={2}>
							{renderActivityStatusDropdown()}
							{headerButtons}
						</Grid2>
					}
				/>
				<ActivityGeneralDetailsForm
					projectId={activityData?.project}
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
				<ActivityDateTime
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
				<ActivityDays
					values={values}
					errors={errors}
					touched={touched}
					disabled={!isEditing}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
				<ActivityRelation
					values={values}
					errors={errors}
					touched={touched}
					disabled={!isEditing}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					currentActivityId={Number(id)}
				/>
				<ActivityOtherDetails
					values={values}
					errors={errors}
					disabled={!isEditing}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
				<SubActivitiesList subActivities={subActivities?.results || []} />
			</form>
		</Box>
	)
}
