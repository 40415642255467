import { useMemo, useState } from "react"
import { Avatar, Box, Typography } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import { styled, Theme, CSSObject } from "@mui/material/styles"
import IconExpandLess from "@mui/icons-material/ExpandLess"
import IconExpandMore from "@mui/icons-material/ExpandMore"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import { Svgs } from "../../assets/svgs"
import { routeUrls } from "../../routes/routeUrls"
import "./AppSidebar.scss"
import { NavLink, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useAuthData from "../../hooks/useAuthData"
import { ProjectUserRole, UserRole } from "../../api/types/Auth"
import { getUserInitials } from "../../utils"
import { logoutUser, clearAllApiCache } from "../../utils/authUtils"
import { AppColors } from "../../theme"
import LogoutConfirmModal from "./LogoutConfirmModal"
import { useLogoutMutation } from "../../api/network/userApiService"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import useUserRole from "../../hooks/useUserRole"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"

const drawerWidth = 256

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

export const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	background: theme.palette.primary.main,
	"& .MuiDrawer-paper": {
		background: "inherit",
	},
	variants: [
		{
			props: ({ open }) => open,
			style: {
				...openedMixin(theme),
				"& .MuiDrawer-paper": openedMixin(theme),
			},
		},
		{
			props: ({ open }) => !open,
			style: {
				...closedMixin(theme),
				"& .MuiDrawer-paper": closedMixin(theme),
			},
		},
	],
}))

const superAdminModules = [
	{
		name: "dashboard",
		path: routeUrls.dashboard,
		Icon: Svgs.Dashboard,
	},
	{
		name: "customer",
		Icon: Svgs.Customer,
		items: [
			{
				name: "companies",
				path: routeUrls.dashboardChild.company,
			},
			{
				name: "users",
				path: routeUrls.users.list,
			},
		],
	},
	{
		name: "projects",
		path: routeUrls.projects.list,
		Icon: Svgs.Project,
	},
	{
		name: "activities",
		path: routeUrls.activity.list,
		Icon: Svgs.Activity,
	},
	// {
	// 	name: "sub_activities",
	// 	path: routeUrls.subActivity.list,
	// 	Icon: Svgs.Activity,
	// },
	{
		name: "logs",
		path: routeUrls.logs,
		Icon: Svgs.Logs,
	},
	{
		name: "feedback",
		path: routeUrls.feedback_list,
		Icon: Svgs.FeedBack_Icon,
	},
]

const companyAdminModules = [
	{
		name: "dashboard",
		path: routeUrls.dashboard,
		Icon: Svgs.Dashboard,
	},
	{
		name: "customer",
		Icon: Svgs.Customer,
		items: [
			{
				name: "users",
				path: routeUrls.users.list,
			},
		],
	},
	{
		name: "projects",
		path: routeUrls.projects.list,
		Icon: Svgs.Project,
	},
	// {
	// 	name: "activities",
	// 	path: routeUrls.activity.list,
	// 	Icon: Svgs.Activity,
	// },
	// {
	// 	name: "sub_activities",
	// 	path: routeUrls.subActivity.list,
	// 	Icon: Svgs.Activity,
	// },
	{
		name: "logs",
		path: routeUrls.logs,
		Icon: Svgs.Logs,
	},
]

const userModules = [
	{
		name: "dashboard",
		path: routeUrls.dashboard,
		Icon: Svgs.Dashboard,
	},
	{
		name: "company",
		path: routeUrls.myCompany,
		Icon: Svgs.Customer,
	},
]

const AppMenuItem = ({
	name,
	Icon,
	path = "",
	items = [],
	nested = false,
}: any) => {
	const isExpandable = items && items.length > 0
	const [open, setOpen] = useState(false)

	const { t } = useTranslation("translation", {
		keyPrefix: "common.sideNavbar",
	})

	function handleClick() {
		setOpen(!open)
	}

	const MenuItemRoot = (
		<NavLink
			to={path ? path : "#"}
			className={({ isActive }) =>
				isActive && path ? "nav-item active-link" : "nav-item"
			}
		>
			<ListItem
				onClick={handleClick}
				sx={{
					"&.MuiListItem-root": {
						padding: "10px",
					},
					"& .MuiListItemIcon-root": {
						minWidth: "40px",
					},
					"& .MuiListItemText-root": {
						marginTop: 0,
						marginBottom: 0,
					},
					marginLeft: nested ? "50px" : "0",
					width: nested ? "auto" : "100%",
				}}
			>
				{!!Icon && (
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
				)}
				<ListItemText>{t(name)}</ListItemText>
				{/* Display the expand menu if the item has children */}
				{isExpandable && !open && <IconExpandMore />}
				{isExpandable && open && <IconExpandLess />}
			</ListItem>
		</NavLink>
	)

	const MenuItemChildren = isExpandable ? (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{items.map((item: any, index: number) => (
					<AppMenuItem {...item} key={index} nested />
				))}
			</List>
		</Collapse>
	) : null

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	)
}

const AppSidebar = () => {
	const [openLogoutConfirmModal, setOpenLogoutConfirmModal] = useState(false)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { currentProject } = useSelector(
		(state: RootReduxState) => state.userProjectData,
	)

	const [logoutApiTrigger, { isLoading: isLogoutLoading }] = useLogoutMutation()

	const { t } = useTranslation("translation", {
		keyPrefix: "common.sideNavbar",
	})

	const authData = useAuthData()
	const appUserRole = useUserRole()

	const allowedModules = useMemo(() => {
		if (appUserRole === UserRole.User) {
			return userModules
		}
		return appUserRole === UserRole.SuperAdmin
			? superAdminModules
			: companyAdminModules
	}, [appUserRole])

	const openLogoutConfirmPopup = () => {
		setOpenLogoutConfirmModal(true)
	}

	const closeLogoutConfirmPopup = () => {
		setOpenLogoutConfirmModal(false)
	}

	const handleLogout = () => {
		logoutApiTrigger(null)
			.unwrap()
			.then((res) => {
				logoutUser()
				if (res.detail) toast.success(res.detail)
				setTimeout(() => clearAllApiCache(dispatch))
			})
			.catch((err) => {
				console.log("Error while logging out the user", err)
				toast.error(t("logout_error"))
			})
	}

	const navigateToSettings = () => {
		navigate(routeUrls.profile)
	}

	const renderCompanyLogo = () => {
		return appUserRole !== UserRole.SuperAdmin ? (
			authData?.user?.company_logo ? (
				<img
					src={authData?.user.company_logo || ""}
					alt="Company Logo"
					style={{
						marginTop: "24px",
						height: "56px",
						objectFit: "contain",
					}}
				/>
			) : (
				<Box sx={{ marginTop: "10px" }} />
			)
		) : (
			<Box sx={{ marginTop: "10px" }} />
		)
	}

	const renderSelectedProjectIfUser = () => {
		if (appUserRole === UserRole.User && currentProject) {
			return (
				<div className="selected-project-detail">
					<Typography>{currentProject.project_name}</Typography>
				</div>
			)
		}
		return null
	}

	const renderProjectRoleBasedModules = () => {
		if (
			currentProject &&
			currentProject.role === ProjectUserRole.SuperIntendent
		) {
			return (
				<AppMenuItem
					name="users"
					Icon={Svgs.Customer}
					path={routeUrls.users.list}
				/>
			)
		}
	}

	return (
		<Drawer variant="permanent" open={true}>
			<div className="sidebar-nav-content-container">
				<Box display="flex" flexDirection="column" padding="14px 12px 0 12px">
					<Box
						display="flex"
						flex={1}
						justifyContent="space-between"
						alignItems={"center"}
					>
						<Svgs.SidebarLogo />
					</Box>
					{renderCompanyLogo()}
					<div className="nav-list-wrapper">
						{renderSelectedProjectIfUser()}
						{allowedModules.map((item: any, index: number) => (
							<AppMenuItem {...item} key={index} />
						))}
						{renderProjectRoleBasedModules()}
					</div>
				</Box>
				<Box display="flex" flexDirection="column">
					<Box
						display="flex"
						gap="8px"
						alignItems="center"
						sx={{
							cursor: "pointer",
							marginTop: "20px",
							marginLeft: "10px",
							marginRight: "10px",
							padding: "10px",
							transition: "all .3s",
							borderRadius: "8px",
							"&:hover": {
								background: "rgba(255, 255, 255, 0.3)",
							},
						}}
						onClick={openLogoutConfirmPopup}
					>
						<Svgs.Logout />
						<Typography
							fontSize="16px"
							fontWeight="500"
							lineHeight="19.5px"
							color="white"
						>
							{t("logout")}
						</Typography>
					</Box>
					<Box
						mt="10px"
						display="flex"
						alignItems="center"
						sx={{
							height: "56px",
							background: "#525c71",
							padding: "0 12px",
						}}
					>
						<Box display="flex" flex={1} alignItems="center">
							<Box flex={1} display="flex" alignItems="center" gap="6px">
								<Avatar
									variant="rounded"
									sx={{
										background: AppColors.secondary,
										width: "32px",
										height: "32px",
										fontSize: "16px",
										fontWeight: "500",
										lineHeight: "19.54px",
									}}
								>
									{getUserInitials(authData?.user.name || "")}
								</Avatar>
								<Box display="flex" flexDirection="column" gap="4px">
									<Typography className="user-name">
										{authData?.user.name || ""}
									</Typography>
									<Typography className="user-email" textOverflow="ellipsis">
										{authData?.user.email}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									cursor: "pointer",
									display: "flex",
									alignItems: "center",
								}}
								onClick={navigateToSettings}
							>
								<Svgs.Settings />
							</Box>
						</Box>
					</Box>
				</Box>
			</div>
			<LogoutConfirmModal
				open={openLogoutConfirmModal}
				onConfirm={handleLogout}
				onClose={closeLogoutConfirmPopup}
				isLogoutLoading={isLogoutLoading}
			/>
		</Drawer>
	)
}

export default AppSidebar
