/* PLEASE NOTE:
 * This file is used to store all the constants used in the application.
 * For API endpoints, please check the `../api/endpoints.ts` file.
 */

export const APP_CONFIG = {
	APP_NAME: "BRG Pull Pro",
	PASSWORD: {
		MIN_LENGTH: 10,
	},
	REGEX: {
		ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
		VIN: /^[A-HJ-NPR-Z0-9]{17}$/,
		NUMERIC: /^[0-9]*$/,
		YEAR: /^[0-9]{4}$/,
		LICENSE_PLATE: /^[a-zA-Z0-9 ]*$/,
		PHONE_NUMBER: /^[0-9]{10}$/,
	},
	DEFAULT_TIMEZONE: "US/Central",
	LISTINGS: {
		PAGE_SIZES: [10, 20, 50, 100],
		DEFAULT_PAGE_SIZE: 10,
	},
}

// API related constants
export const RESPONSE_CODE = {
	UNAUTHORIZED: 401,
	SUCCESS: 200,
	CREATED: 201,
	BAD_REQUEST: 400,
	NOT_FOUND: 404,
	FATAL_SERVER_ERROR: 500,
	PARSING_ERROR: "PARSING_ERROR",
	FETCH_ERROR: "FETCH_ERROR",
}

export enum API_METHODS {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE",
	PATCH = "PATCH",
}

// Storage keys
export const localStorageKeys = {
	userData: "user-data",
}

export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{10,}$/

export const sortData = (data: any[], key: string, order: boolean) => {
	// Create a shallow copy of the array to avoid modifying the original
	const sortedData = [...data].sort((a, b) => {
		if (order) {
			return a[key] > b[key] ? 1 : -1
		} else {
			return a[key] < b[key] ? 1 : -1
		}
	})
	console.log(sortedData)
	return sortedData
}

export const useDebounce = (func: (...args: any[]) => void, delay: number) => {
	let timer: any
	return function (...args: any) {
		clearTimeout(timer)
		timer = setTimeout(() => {
			func(...args)
		}, delay)
	}
}

export const filterData = (data: any[], searchTerm: string) => {
	return data.filter((item) => {
		return Object.values(item).some((value) => {
			if (typeof value === "string") {
				return value.toLowerCase().includes(searchTerm.toLowerCase())
			}
			return false
		})
	})
}

export const phoneRegex = /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;
