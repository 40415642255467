import { Box, Typography } from "@mui/material"
import { Drawer } from "../../components/AppSidebar/AppSidebar"
import SwimlaneCalender from "./SwimlaneCalender"
import SwimlaneLists from "./SwimlaneLists"
import { Svgs } from "../../assets/svgs"

const Swimlane = () => {
	return (
		<Box display={"flex"}>
			<Box>
				<Drawer variant="permanent" open={true}>
					<Box
						display="flex"
						flexDirection="column"
						padding="14px 12px 12px 12px"
					>
						<Box
							display="flex"
							flex={1}
							justifyContent="space-between"
							alignItems={"center"}
						>
							<Svgs.SidebarLogo />
						</Box>
					</Box>
					<SwimlaneLists />
				</Drawer>
			</Box>
			<Box flex={1} padding={2}>
				<Box padding={2}>
					<Box>
						<Typography fontWeight={700} fontSize={24}>
							Planning of Foundation Work
						</Typography>
						<Typography fontWeight={500} fontSize={14}>
							SESS-01 November 10, 2024 - November 17, 2024
						</Typography>
					</Box>
				</Box>
				<Box mt={4}>
					<SwimlaneCalender />
				</Box>
			</Box>
		</Box>
	)
}

export default Swimlane
