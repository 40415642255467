import { Backdrop, CircularProgress } from "@mui/material"
import { FC } from "react"

interface IAppLoaderProps {
	open: boolean
	handleClose?: () => void
}

const AppLoader: FC<IAppLoaderProps> = (props: IAppLoaderProps) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: 1 }}
			open={props.open}
			onClick={props.handleClose}
		>
			<CircularProgress />
		</Backdrop>
	)
}

export default AppLoader
