import { Box, Grid2 } from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import {IAddSubActivitySectionFormProps } from "./validation"
export default function SubActivityOtherDetails({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	disabled,
	handleBlur,
}: IAddSubActivitySectionFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				marginBottom: 4,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 className="title">{t("notes")}</Grid2>
			<Grid2 mb={1}>
				<AppFormField
					label={'Notes'}
					name={"notes"}
					placeholder={t("notes")}
					value={values.notes}
					disabled={disabled}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.notes}
					// errorMsg={errors.notes}
				/>
			</Grid2>
		</Box>
	)
}
