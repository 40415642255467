//  dummy data, to be removed later with actual api call data

import { TimelineGroupBase } from "react-calendar-timeline"

interface Group extends TimelineGroupBase {
	id: number
	title: string
}

export interface Item {
	id: number
	group: number
	title: string
	subtitle: string
	start_time: Date
	end_time: Date
	linkedTo?: string
	type?: string
	status?: string
	linkType?: string
	stackItems?: boolean
}

export const groups: Group[] = [
	{ id: 1, title: "", height: 100, stackItems: true },
	{ id: 2, title: "", height: 100, stackItems: true },
	{ id: 3, title: "", height: 100, stackItems: true },
	{ id: 4, title: "", height: 100, stackItems: true },
	{ id: 5, title: "", height: 100, stackItems: true },
]

export const items: Item[] = [
	{
		id: 1,
		group: 1,
		title: "ACT-1001",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 5),
		end_time: new Date(2024, 10, 8),
		linkedTo: "2",
		type: "painting",
		status: "delayed",
		linkType: "FS",
		stackItems: true,
	},
	{
		id: 2,
		group: 4,
		title: "ACT-1002",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 9),
		end_time: new Date(2024, 10, 12),
		type: "electrician",
		status: "early",
	},
	{
		id: 3,
		group: 2,
		title: "ACT-1003",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 15),
		end_time: new Date(2024, 10, 18),
		type: "electrician",
		status: "early",
		linkedTo: "2",
		linkType: "SF",
	},
]

export const items2: Item[] = [
	{
		id: 11,
		group: 1,
		title: "ACT-1001",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 5),
		end_time: new Date(2024, 10, 8),
		linkedTo: "12",
		type: "painting",
		status: "delayed",
		linkType: "FS",
		stackItems: true,
	},
	{
		id: 12,
		group: 4,
		title: "ACT-1002",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 9),
		end_time: new Date(2024, 10, 12),
		type: "electrician",
		status: "early",
	},
	{
		id: 13,
		group: 2,
		title: "ACT-1003",
		subtitle: "Install Electrical Wiring",
		start_time: new Date(2024, 10, 15),
		end_time: new Date(2024, 10, 18),
		type: "electrician",
		status: "early",
		linkedTo: "12",
		linkType: "SF",
	},
]





export const swimlane_activities_list = {
	count: 74,
	next: "http://127.0.0.1:8000/project/swimlanes/swimlane_by_activity_code/?page=2&project=28",
	previous: null,
	page_size: 50,
	results: [
		{
			actv_code_name: "Construction Activity",
			actv_code_id: "138621",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Procurement Activity",
			actv_code_id: "138622",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Progress Milestone",
			actv_code_id: "138623",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Level of Effort",
			actv_code_id: "138624",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Impact Activity",
			actv_code_id: "138625",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Weather Day",
			actv_code_id: "138626",
			actv_code_type_id: "Activity Category - Swimlane",
		},
		{
			actv_code_name: "Building A",
			actv_code_id: "138627",
			actv_code_type_id: "Building - Swimlane",
		},
		{
			actv_code_name: "Building B",
			actv_code_id: "138628",
			actv_code_type_id: "Building - Swimlane",
		},
		{
			actv_code_name: "Both Buildings",
			actv_code_id: "138629",
			actv_code_type_id: "Building - Swimlane",
		},
		{
			actv_code_name: "Floor 1 Building A",
			actv_code_id: "138630",
			actv_code_type_id: "Floor/Level - Swimlane",
		},
		{
			actv_code_name: "Floor 3 Building A",
			actv_code_id: "138631",
			actv_code_type_id: "Floor/Level - Swimlane",
		},
		{
			actv_code_name: "Floor 4 Building A",
			actv_code_id: "138632",
			actv_code_type_id: "Floor/Level - Swimlane",
		},
		{
			actv_code_name: "Floor 5 Building A",
			actv_code_id: "138633",
			actv_code_type_id: "Floor/Level - Swimlane",
		},
	],
}

export const swimlane_wbs_list = {
	count: 1,
	next: null,
	previous: null,
	page_size: 50,
	results: [
		{
			wbs_code: "639543",
			wbs_name: "BRG/PullPro Coded Sample Schedule",
			children: [
				{
					wbs_code: "639562",
					wbs_name: "CONSTRUCTION SCHEDULE",
					children: [
						{
							wbs_code: "639722",
							wbs_name: "BUILDING A",
							children: [
								{
									wbs_code: "639723",
									wbs_name: "CIVIL",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639778",
									wbs_name: "CLOSEOUT",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639751",
									wbs_name: "EXTERIOR ENVELOPE",
									children: [
										{
											wbs_code: "639753",
											wbs_name: "CONDENSING UNITS",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639754",
											wbs_name: "PODIUM EXTERIOR/FACADE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639752",
											wbs_name: "ROOFING",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639775",
									wbs_name: "FINISH SITEWORK",
									children: [
										{
											wbs_code: "639776",
											wbs_name: "BUILDING EXTERIOR",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639777",
											wbs_name: "COURTYARDS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639755",
									wbs_name: "INTERIOR",
									children: [
										{
											wbs_code: "639771",
											wbs_name: "GARAGE",
											children: [
												{
													wbs_code: "639774",
													wbs_name: "ELECTRICAL ROOM A160",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639772",
													wbs_name: "GARAGE FINISHES",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639773",
													wbs_name: "TRANSFORMER VAULT",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639763",
											wbs_name: "INTERIOR WORK BY LEVEL",
											children: [
												{
													wbs_code: "639770",
													wbs_name: "LEVEL 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639769",
													wbs_name: "LEVEL 3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639768",
													wbs_name: "LEVEL 4",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639767",
													wbs_name: "LEVEL 5",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639766",
													wbs_name: "LEVEL 6",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639765",
													wbs_name: "LEVEL 7",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639764",
													wbs_name: "WEATHER RESISTANT ROUGHIN",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639756",
											wbs_name: "STAIRS & ELEVATORS",
											children: [
												{
													wbs_code: "639761",
													wbs_name: "ELEVATOR A1 & A2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639760",
													wbs_name: "ELEVATOR A3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639759",
													wbs_name: "STAIR 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639758",
													wbs_name: "STAIR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639757",
													wbs_name: "STAIR 3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639762",
													wbs_name: "TRASH CHUTE INSTALL - BUILDING A",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639724",
									wbs_name: "STRUCTURAL",
									children: [
										{
											wbs_code: "639745",
											wbs_name: "CMU STAIR SHAFTS",
											children: [
												{
													wbs_code: "639747",
													wbs_name: "ELEVATOR 1 & 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639749",
													wbs_name: "STAIR 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639748",
													wbs_name: "STAIR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639746",
													wbs_name: "STAIR 3 / ELEVATOR 3",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639725",
											wbs_name: "CONCRETE PODIUM",
											children: [
												{
													wbs_code: "639727",
													wbs_name: "ELEVATOR PIT",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639728",
													wbs_name: "LEVEL 1",
													children: [
														{
															wbs_code: "639732",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639729",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639730",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639731",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639733",
													wbs_name: "LEVEL 2",
													children: [
														{
															wbs_code: "639738",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639734",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639735",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639736",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639737",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639739",
													wbs_name: "LEVEL 3",
													children: [
														{
															wbs_code: "639744",
															wbs_name: "POOL BOTTOM",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639740",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639741",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639742",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639743",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639726",
													wbs_name: "VAULT",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639750",
											wbs_name: "WOOD FRAMING",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639779",
							wbs_name: "BUILDING B",
							children: [
								{
									wbs_code: "639831",
									wbs_name: "CLOSEOUT",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639802",
									wbs_name: "EXTERIOR ENVELOPE",
									children: [
										{
											wbs_code: "639804",
											wbs_name: "CONDENSING UNITS",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639805",
											wbs_name: "PODIUM EXTERIOR/FACADE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639803",
											wbs_name: "ROOFING",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639828",
									wbs_name: "FINISH SITEWORK",
									children: [
										{
											wbs_code: "639829",
											wbs_name: "BUILDING EXTERIOR",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639830",
											wbs_name: "COURTYARDS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639806",
									wbs_name: "INTERIOR",
									children: [
										{
											wbs_code: "639824",
											wbs_name: "GARAGE",
											children: [
												{
													wbs_code: "639827",
													wbs_name: "ELECTRICAL ROOM B160",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639825",
													wbs_name: "GARAGE FINISHES",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639826",
													wbs_name: "TRANSFORMER VAULT",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639814",
											wbs_name: "INTERIOR WORK BY LEVEL",
											children: [
												{
													wbs_code: "639821",
													wbs_name: "LEVEL 1",
													children: [
														{
															wbs_code: "639822",
															wbs_name: "AMENITY",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639823",
															wbs_name: "RESIDENTIAL",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639820",
													wbs_name: "LEVEL 3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639819",
													wbs_name: "LEVEL 4",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639818",
													wbs_name: "LEVEL 5",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639817",
													wbs_name: "LEVEL 6",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639816",
													wbs_name: "LEVEL 7",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639815",
													wbs_name: "WEATHER RESISTANT ROUGHIN",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639807",
											wbs_name: "STAIRS & ELEVATORS",
											children: [
												{
													wbs_code: "639812",
													wbs_name: "ELEVATOR B1 & B2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639811",
													wbs_name: "ELEVATOR B3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639810",
													wbs_name: "STAIR 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639809",
													wbs_name: "STAIR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639808",
													wbs_name: "STAIR 3",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639813",
													wbs_name: "TRASH CHUTE INSTALL - BUILDING B",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639780",
									wbs_name: "STRUCTURAL",
									children: [
										{
											wbs_code: "639796",
											wbs_name: "CMU STAIR SHAFTS",
											children: [
												{
													wbs_code: "639799",
													wbs_name: "ELEVATOR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639800",
													wbs_name: "STAIR 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639798",
													wbs_name: "STAIR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639797",
													wbs_name: "STAIR 3 / ELEVATOR 3",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639781",
											wbs_name: "CONCRETE PODIUM",
											children: [
												{
													wbs_code: "639782",
													wbs_name: "LEVEL 1",
													children: [
														{
															wbs_code: "639783",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639784",
													wbs_name: "LEVEL 2",
													children: [
														{
															wbs_code: "639789",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639785",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639786",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639787",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639788",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639790",
													wbs_name: "LEVEL 3",
													children: [
														{
															wbs_code: "639795",
															wbs_name: "POOL BOTTOM",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639791",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639792",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639793",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639794",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639801",
											wbs_name: "WOOD FRAMING",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639644",
							wbs_name: "INITIAL BUILDING B",
							children: [
								{
									wbs_code: "639645",
									wbs_name: "CIVIL",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639719",
									wbs_name: "CLOSEOUT",
									children: [
										{
											wbs_code: "639720",
											wbs_name: "ZONE 1",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639721",
											wbs_name: "ZONE 2",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639687",
									wbs_name: "EXTERIOR ENVELOPE",
									children: [
										{
											wbs_code: "639688",
											wbs_name: "ZONE 1",
											children: [
												{
													wbs_code: "639690",
													wbs_name: "CONDENSING UNITS",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639692",
													wbs_name: "NORTH",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639693",
													wbs_name: "NORTHWEST",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639691",
													wbs_name: "PODIUM EXTERIOR",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639689",
													wbs_name: "ROOFING",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639694",
													wbs_name: "ZONE 1 COURTYARD",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639695",
											wbs_name: "ZONE 2",
											children: [
												{
													wbs_code: "639699",
													wbs_name: "CENTER WEST",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639697",
													wbs_name: "CONDENSING UNITS",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639703",
													wbs_name: "EAST",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639698",
													wbs_name: "PODIUM EXTERIOR",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639696",
													wbs_name: "ROOFING",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639702",
													wbs_name: "SOUTH",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639701",
													wbs_name: "SOUTHWEST",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639700",
													wbs_name: "ZONE 2 COURTYARD",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639718",
									wbs_name: "FINISH SITEWORK",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639704",
									wbs_name: "INTERIOR",
									children: [
										{
											wbs_code: "639714",
											wbs_name: "GARAGE LEVEL 1",
											children: [
												{
													wbs_code: "639717",
													wbs_name: "ELECTRICAL ROOM B160",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639715",
													wbs_name: "GARAGE FINISHES",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639716",
													wbs_name: "TRANSFORMER VAULT",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639712",
											wbs_name: "GARAGE LEVEL 2",
											children: [
												{
													wbs_code: "639713",
													wbs_name: "GARAGE FINISHES",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639705",
											wbs_name: "LEVEL 1 - AMENITY SPACE",
											children: [
												{
													wbs_code: "639707",
													wbs_name: "INSULATION",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639706",
													wbs_name: "ROUGHIN",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639708",
											wbs_name: "ZONE 1 UNITS",
											children: [
												{
													wbs_code: "639709",
													wbs_name: "WEATHER RESISTANT ROUGHIN",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639710",
											wbs_name: "ZONE 2 UNITS",
											children: [
												{
													wbs_code: "639711",
													wbs_name: "WEATHER RESISTANT ROUGHIN",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639646",
									wbs_name: "STRUCTURAL",
									children: [
										{
											wbs_code: "639667",
											wbs_name: "CMU STAIR SHAFTS",
											children: [
												{
													wbs_code: "639670",
													wbs_name: "ELEVATOR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639671",
													wbs_name: "STAIR 1",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639669",
													wbs_name: "STAIR 2",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639668",
													wbs_name: "STAIR 3 / ELEVATOR 3",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639647",
											wbs_name: "CONCRETE PODIUM",
											children: [
												{
													wbs_code: "639649",
													wbs_name: "ELEVATOR PIT",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639650",
													wbs_name: "LEVEL 1",
													children: [
														{
															wbs_code: "639654",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639651",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639652",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639653",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639655",
													wbs_name: "LEVEL 2",
													children: [
														{
															wbs_code: "639660",
															wbs_name: "CORE",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639656",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639657",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639658",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639659",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639661",
													wbs_name: "LEVEL 3",
													children: [
														{
															wbs_code: "639666",
															wbs_name: "POOL BOTTOM",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639662",
															wbs_name: "POUR 1",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639663",
															wbs_name: "POUR 2",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639664",
															wbs_name: "POUR 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639665",
															wbs_name: "POUR 4",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639648",
													wbs_name: "VAULT",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639672",
											wbs_name: "WOOD FRAMING",
											children: [
												{
													wbs_code: "639673",
													wbs_name: "ZONE 1",
													children: [
														{
															wbs_code: "639674",
															wbs_name: "LEVEL 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639675",
															wbs_name: "LEVEL 4",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639676",
															wbs_name: "LEVEL 5",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639677",
															wbs_name: "LEVEL 6",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639678",
															wbs_name: "LEVEL 7",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639679",
															wbs_name: "ROOF",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639680",
													wbs_name: "ZONE 2",
													children: [
														{
															wbs_code: "639681",
															wbs_name: "LEVEL 3",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639682",
															wbs_name: "LEVEL 4",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639683",
															wbs_name: "LEVEL 5",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639684",
															wbs_name: "LEVEL 6",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639685",
															wbs_name: "LEVEL 7",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639686",
															wbs_name: "ROOF",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
									],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639563",
							wbs_name: "MILESTONES",
							children: [
								{
									wbs_code: "639569",
									wbs_name: "BUILDING A",
									children: [
										{
											wbs_code: "639573",
											wbs_name: "AMENITY",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639571",
											wbs_name: "EXTERIOR",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639570",
											wbs_name: "GARAGE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639572",
											wbs_name: "UNITS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639564",
									wbs_name: "BUILDING B",
									children: [
										{
											wbs_code: "639568",
											wbs_name: "AMENITY",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639566",
											wbs_name: "EXTERIOR",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639565",
											wbs_name: "GARAGE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639567",
											wbs_name: "UNITS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639574",
									wbs_name: "BUILDING B REBUILD",
									children: [
										{
											wbs_code: "639578",
											wbs_name: "AMENITY",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639576",
											wbs_name: "EXTERIOR",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639575",
											wbs_name: "GARAGE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639577",
											wbs_name: "UNITS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639579",
							wbs_name: "PRECONSTRUCTION",
							children: [
								{
									wbs_code: "639580",
									wbs_name: "PROCUREMENT",
									children: [
										{
											wbs_code: "639582",
											wbs_name: "AMENITY COUNTERTOPS",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639609",
											wbs_name: "APPLIANCES",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639610",
											wbs_name: "ARCHITECTURAL",
											children: [
												{
													wbs_code: "639614",
													wbs_name: "BUILDING A",
													children: [
														{
															wbs_code: "639615",
															wbs_name: "CABINETS",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639616",
															wbs_name: "COUNTERTOPS",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639611",
													wbs_name: "BUILDING B",
													children: [
														{
															wbs_code: "639612",
															wbs_name: "CABINETS",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639613",
															wbs_name: "COUNTERTOPS",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639624",
											wbs_name: "ELECTRICAL",
											children: [
												{
													wbs_code: "639629",
													wbs_name: "ELECTRICAL GEAR",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639625",
													wbs_name: "LIGHT FIXTURES",
													children: [
														{
															wbs_code: "639628",
															wbs_name: "AMENITY LIGHT FIXTURES",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639627",
															wbs_name: "COMMON AREA LIGHT FIXTURES",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639626",
															wbs_name: "UNIT LIGHT FIXTURES",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639630",
											wbs_name: "ELEVATOR",
											children: [
												{
													wbs_code: "639632",
													wbs_name: "BUILDING A",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639631",
													wbs_name: "BUILDING B",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639623",
											wbs_name: "LIGHT FIXTURES",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639618",
											wbs_name: "MECHANICAL",
											children: [
												{
													wbs_code: "639621",
													wbs_name: "BUILDING A",
													children: [
														{
															wbs_code: "639622",
															wbs_name: "AHUs",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639619",
													wbs_name: "BUILDING B",
													children: [
														{
															wbs_code: "639620",
															wbs_name: "AHUs",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639581",
											wbs_name: "MITM WALL PANEL",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639584",
											wbs_name: "MOCKUP",
											children: [
												{
													wbs_code: "639586",
													wbs_name: "EXTERIOR",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639585",
													wbs_name: "INTERIOR",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639617",
											wbs_name: "OVERHEAD DOORS",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639588",
											wbs_name: "STRUCTURAL",
											children: [
												{
													wbs_code: "639589",
													wbs_name: "REBAR SHOPS",
													children: [
														{
															wbs_code: "639595",
															wbs_name: "BUILDING A",
															children: [
																{
																	wbs_code: "639596",
																	wbs_name: "FOUNDATIONS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639598",
																	wbs_name: "LEVEL 2 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639599",
																	wbs_name: "LEVEL 3 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639597",
																	wbs_name: "SOG REBAR SHOPS",
																	children: [],
																	level: 8,
																},
															],
															level: 7,
														},
														{
															wbs_code: "639590",
															wbs_name: "BUILDING B",
															children: [
																{
																	wbs_code: "639591",
																	wbs_name: "FOUNDATIONS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639593",
																	wbs_name: "LEVEL 2 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639594",
																	wbs_name: "LEVEL 3 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639592",
																	wbs_name: "SOG REBAR SHOPS",
																	children: [],
																	level: 8,
																},
															],
															level: 7,
														},
														{
															wbs_code: "639600",
															wbs_name: "BUILDING B REBUILD  REBAR",
															children: [
																{
																	wbs_code: "639601",
																	wbs_name: "FOUNDATIONS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639602",
																	wbs_name: "LEVEL 1 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639603",
																	wbs_name: "LEVEL 2 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
																{
																	wbs_code: "639604",
																	wbs_name: "LEVEL 3 REBAR SHOPS",
																	children: [],
																	level: 8,
																},
															],
															level: 7,
														},
													],
													level: 6,
												},
												{
													wbs_code: "639605",
													wbs_name: "TRUSSES",
													children: [
														{
															wbs_code: "639607",
															wbs_name: "BUILDING A TRUSS SHOPS",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639608",
															wbs_name: "BUILDING B REBUILD TRUSSES",
															children: [],
															level: 7,
														},
														{
															wbs_code: "639606",
															wbs_name: "BUILDING B TRUSS SHOPS",
															children: [],
															level: 7,
														},
													],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639633",
											wbs_name: "TPO ROOFING - BUILDING A",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639587",
											wbs_name: "UNIT ENTRY HARDWARE",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639583",
											wbs_name: "WALL COVERINGS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639634",
									wbs_name: "RE-PROCUREMENT POST-LOSS",
									children: [
										{
											wbs_code: "639635",
											wbs_name: "STOREFRONT",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639641",
											wbs_name: "THIN STONE VENEER",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639637",
											wbs_name: "TPO ROOFING - BUILDING B",
											children: [],
											level: 5,
										},
										{
											wbs_code: "639638",
											wbs_name: "TRUSSES",
											children: [
												{
													wbs_code: "639639",
													wbs_name: "BUILDING A",
													children: [],
													level: 6,
												},
												{
													wbs_code: "639640",
													wbs_name: "BUILDING B",
													children: [],
													level: 6,
												},
											],
											level: 5,
										},
										{
											wbs_code: "639636",
											wbs_name: "VINYL WINDOWS",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639642",
							wbs_name: "SITE UTILITIES",
							children: [
								{
									wbs_code: "639643",
									wbs_name: "WATER TAPS",
									children: [],
									level: 4,
								},
							],
							level: 3,
						},
					],
					level: 2,
				},
				{
					wbs_code: "639552",
					wbs_name: "SCHEDULE IMPACTS",
					children: [
						{
							wbs_code: "639553",
							wbs_name: "FIRE EVENT",
							children: [
								{
									wbs_code: "639559",
									wbs_name: "BUILDING A",
									children: [
										{
											wbs_code: "639560",
											wbs_name: "SELECTIVE DEMO",
											children: [],
											level: 5,
										},
									],
									level: 4,
								},
								{
									wbs_code: "639561",
									wbs_name: "BUILDING B",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639558",
									wbs_name: "CONSTRAINTS",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639556",
									wbs_name: "CONSTRUCTION ACTIVITY",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639557",
									wbs_name: "DESIGN",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639554",
									wbs_name: "INSPECTIONS AND APPROVALS",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639555",
									wbs_name: "PROCUREMENT",
									children: [],
									level: 4,
								},
							],
							level: 3,
						},
					],
					level: 2,
				},
				{
					wbs_code: "639544",
					wbs_name: "SUMMARY SCHEDULE",
					children: [
						{
							wbs_code: "639549",
							wbs_name: "BUILDING A",
							children: [],
							level: 3,
						},
						{
							wbs_code: "639551",
							wbs_name: "BUILDING B REBUILD",
							children: [],
							level: 3,
						},
						{
							wbs_code: "639545",
							wbs_name: "CONTRACT MILESTONES",
							children: [
								{
									wbs_code: "639546",
									wbs_name: "FIRST RESIDENTIAL TURNOVER",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639548",
									wbs_name: "LAST RESIDENTIAL TURNOVER",
									children: [],
									level: 4,
								},
								{
									wbs_code: "639547",
									wbs_name: "LEASING OFFICE TURNOVER",
									children: [],
									level: 4,
								},
							],
							level: 3,
						},
						{
							wbs_code: "639550",
							wbs_name: "INITIAL BUILDING B",
							children: [],
							level: 3,
						},
					],
					level: 2,
				},
			],
			level: 1,
		},
	],
}
