import * as Yup from "yup"
import { phoneRegex } from "../../constants/constants"

export type TProfileInformationType = "personal" | "professional"

export interface IProfileSetupFields {
	name: string
	email: string
	phone_number: string
	job_title: string
	company: string
	office_phone_number?: string
	onsite_emergency_contact: string
	offsite_emergency_contact_number?: string
}

export const initialProfileSetupValues: IProfileSetupFields = {
	name: "",
	email: "",
	phone_number: "",
	job_title: "",
	company: "",
	office_phone_number: "",
	onsite_emergency_contact: "",
	offsite_emergency_contact_number: "",
}

// export const profileSetupValidationSchema = Yup.object().shape({
// 	name: Yup.string().trim().required("errors.enter_name"),
// 	phone_number: Yup.string().trim().required("errors.enter_phone_number"),
// 	office_phone_number: Yup.string(),
// 	onsite_emergency_contact: Yup.string(),
// 	offsite_emergency_contact_number: Yup.string(),
// })


export const profileSetupValidationSchema = Yup.object().shape({
	name: Yup.string().trim().required("errors.enter_name"),
	phone_number: Yup.string()
		.trim()
		.matches(phoneRegex, "Invalid phone number")
		.required("Phone number is required"),
	office_phone_number: Yup.string().trim().matches(phoneRegex, "Invalid phone number").required("Office phone number is required"),
	onsite_emergency_contact: Yup.string().trim().matches(phoneRegex, "Invalid phone number").required("Onsite emergency contact is required"),
	offsite_emergency_contact_number: Yup.string().trim().matches(phoneRegex, "Invalid phone number"),
});
