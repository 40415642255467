import { CircularProgress, TextField, Typography } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { AutocompleteOption, IAppAutocompleteFormFieldProps } from "./type"
import "./AppFormField.scss"
import { useMemo } from "react"

const AppAutocompleteFormField = ({
	label,
	isRequired = false,
	name,
	highlightLabelIfDataIsValid = false,
	placeholder,
	value,
	multiple,
	onChange,
	onBlur,
	touched,
	errorMsg,
	options,
	showLoader,
	disabled,
	freeSolo = false,
	autoSelect = false,
}: IAppAutocompleteFormFieldProps) => {
	const handleChange = (
		_e: React.SyntheticEvent<Element, Event>,
		value: string | AutocompleteOption | (string | AutocompleteOption)[] | null,
		reason: any,
		details?: any,
	) => {
		onChange?.(
			name,
			value && typeof value === "object" && "id" in value ? value.id : value,
		)
	}

	const selectedValue: any = useMemo(() => {
		if (!value) return null
		else if (multiple) {
			return options?.filter((o) => value.includes(o.id))
		} else {
			const selectedOptionIndex = options?.findIndex((o) => o.id === value)
			let selectedOption = options[selectedOptionIndex]
			if (!selectedOption && freeSolo) {
				selectedOption = { id: value, label: value }
			}
			return selectedOption
		}
	}, [value, options, freeSolo, multiple])

	// Custom filter function to show user's input as the first option
	const filterOptions = (
		options: AutocompleteOption[],
		{ inputValue }: any,
	) => {
		const normalizedInput = inputValue.toLowerCase()

		// Filter and remove duplicates based on the label
		const filteredOptions = options.filter((option) =>
			option?.label?.toLowerCase()?.includes(normalizedInput),
		)

		// Add the input value as the first option if it's not already present
		const isInputInOptions = filteredOptions.some(
			(option) => option?.label?.toLowerCase() === normalizedInput,
		)

		return isInputInOptions
			? filteredOptions
			: inputValue
				? [{ label: inputValue, id: inputValue }, ...filteredOptions]
				: filteredOptions
	}

	return (
		<div className="field-wrapper">
			<Typography
				className={`field-label ${
					highlightLabelIfDataIsValid &&
					(touched ? (errorMsg ? "error" : value ? "secondary" : "") : "")
				}`}
			>
				{label}
				{isRequired && " *"}
			</Typography>
			<Autocomplete
				sx={{
					"& .MuiOutlinedInput-root": {
						paddingLeft: "3px",
						paddingTop: 0,
						paddingBottom: 0,
						paddingRight: "30px",
					},
				}}
				disablePortal
				options={options}
				multiple={multiple}
				filterOptions={freeSolo ? filterOptions : undefined}
				getOptionLabel={(option) =>
					typeof option === "string" ? option : option.label
				} // Display label
				isOptionEqualToValue={(option, value) => option.id === value.id} // Compare by ID
				value={selectedValue || value}
				onChange={handleChange}
				disabled={disabled}
				freeSolo={freeSolo}
				autoSelect={autoSelect}
				renderOption={(props, option) => (
					<li {...props} key={option.id || `custom-${option.label}`}>
						{option.label}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={placeholder}
						error={Boolean(touched && errorMsg)}
						helperText={touched && errorMsg}
						onBlur={onBlur}
						slotProps={{
							htmlInput: {
								...(params.inputProps || {}),
								style: {
									padding: "12px",
									fontSize: "14px",
									lineHeight: "20px",
								},
							},
							input: {
								...(params.InputProps || {}),
								style: {
									backgroundColor: disabled ? "#E6E6E6" : "#FAFAFA",
								},
								endAdornment: (
									<>
										{showLoader ? (
											<CircularProgress color="primary" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</>
								),
							},
						}}
					/>
				)}
				noOptionsText="No options"
			/>
		</div>
	)
}

export default AppAutocompleteFormField
