import React from "react"
import Xarrow from "react-xarrows"

type LineType = {
	id: number
	linkedTo?: string
	linkType?: string
}

type LineTypeProps = {
	lines: LineType[]
}

const LineConnect: React.FC<LineTypeProps> = ({ lines }) => {
	return (
		<div>
			{lines.map((line: LineType) =>
				line.linkedTo ? (
					<Xarrow
						key={line.id}
						start={line.id.toString()}
						end={line.linkedTo.toString()}
						color="#389c97"
						strokeWidth={3}
						headSize={7}
						labels={{
							middle: (
								<div
									style={{
										fontSize: "20px",
										padding: 5,
										backgroundColor: "rgba(0,0,0,.2)",
										borderRadius: 5,
									}}
								>
									{line.linkType}
								</div>
							),
						}}
						path="grid"
						gridBreak="50%"
					/>
				) : null,
			)}
		</div>
	)
}

export default LineConnect
