import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"
import { API_METHODS } from "../../constants/constants"
import { handleAuthError } from "./errorHandler"
import { getUserData } from "../../utils/localstorage"
import { TRole } from "../types/Roles"

export const RolesAPIs = createApi({
	reducerPath: "RolesAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			headers.set("Content-Type", "application/json")
			const userData = await getUserData()
			headers.set("Authorization", `Token ${userData?.token}`)
			return headers
		},
	}),
	endpoints: (builder) => ({
		getLimitedRoles: builder.query<TRole[], null>({
			query: () => {
				return {
					url: API_ENDPOINTS.ROLES.GET_LIMITED_ROLES,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: TRole[]) => {
				return data
			},
		}),
	}),
})

export const limitedRolesSelector =
	RolesAPIs.endpoints.getLimitedRoles.select(null)

export const { useGetLimitedRolesQuery } = RolesAPIs
