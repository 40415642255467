import { Box, IconButton, Typography } from "@mui/material"
import { AppColors } from "../../theme"
import { FC } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import "./DetailNavBar.scss"

interface DetailNavBarProps {
	title: string
	subTitle?: string
	showBackButton?: boolean
	onBackPress?: () => void
	rightView?: React.ReactNode
}

const DetailNavBar: FC<DetailNavBarProps> = ({
	title,
	subTitle,
	showBackButton = true,
	onBackPress,
	rightView,
}) => {
	return (
		<div className="heading-wrapper">
			<Box display="flex" alignItems="center" gap="12px">
				{showBackButton && (
					<IconButton
						onClick={onBackPress}
						sx={{
							background: AppColors.secondary,
							"&:hover": {
								background: "rgba(34, 188, 231, 0.6)",
							},
						}}
					>
						<ArrowBackIcon htmlColor="white" />
					</IconButton>
				)}
				<Box display="flex" flexDirection="column">
					<Typography className="title">{title}</Typography>
					{Boolean(subTitle) && (
						<Typography className="subtitle">{subTitle}</Typography>
					)}
				</Box>
			</Box>
			{rightView}
		</div>
	)
}

export default DetailNavBar
