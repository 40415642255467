import { AppColors } from "../theme"

export const projectStatusArray = [
	{ color: AppColors.yellowOrange, status: "To Plan" },
	{ color: AppColors.brgOrange, status: "Planned" },
	{ color: AppColors.brgTeal, status: "In Construction" },
	{ color: AppColors.primaryGreen, status: "Completed" },
]

export const activitiesStatusArray = [
	{ color: AppColors.brgOrange, status: "Planned" },
	{ color: AppColors.brgTeal, status: "In Progress" },
	{ color: AppColors.primaryGreen, status: "Completed" },
]

export const getProjectStatusColor = (status: string) => {
	switch (status) {
		case "To Plan":
			return AppColors.yellowOrange
		case "Planned":
			return AppColors.brgOrange
		case "In Construction":
			return AppColors.brgTeal
		case "Completed":
			return AppColors.primaryGreen
		default:
			return AppColors.primary
	}
}
export const activityStatusArray = [
	{ color: AppColors.brgOrange, status: "Planned", status_code: "planned" },
	{ color: AppColors.brgTeal, status: "In Progress", status_code: "in_progress" },
	{ color: AppColors.primaryGreen, status: "Completed", status_code: "completed" },
]

export const getActivityStatusLabel = (status: string) => {
	switch (status) {
		case "Planned":
		case "planned":
			return "Planned"
		case "In Progress":
		case "in_progress":
			return "In Progress"
		case "Completed":
		case "completed":
			return "Completed"
		default:
			return "Planned"
	}
}

export const getActivityStatusColor = (status: string) => {
	switch (status) {
		case "Planned":
		case "planned":
			return AppColors.brgOrange
		case "In Progress":
		case "in_progress":
			return AppColors.brgTeal
		case "Completed":
		case "completed":
			return AppColors.primaryGreen
		default:
			return AppColors.primary
	}
}

export const getUserInitials = (name: string) => {
	if (!name) return ""

	const [fName, lName] = name.split(" ")
	return `${fName[0]}${lName ? lName[0] : ""}`
}

export const getTotalPages = (totalCount?: number, pageSize?: number) => {
	return Math.ceil((totalCount || 1) / (pageSize || 1))
}

export const FeedbackStatus = [
	{ color: AppColors.yellowOrange, status: "Pending" },
	{ color: AppColors.brgTeal, status: "Replied" },
	{ color: AppColors.primaryGreen, status: "Resolved" },
]
