import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Grid2, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { format as dateFnsFormat } from "date-fns"
import {
	CreateProjectValidationSchema,
	CreateProjectFormInitialValues,
	ICreateProjectFields,
} from "./validation"
import AppButton from "../../../components/AppButton/AppButton"
import AppFormField from "../../../components/AppFormField/AppFormField"
import AppAutocompleteFormField from "../../../components/AppFormField/AppAutocompleteFormField"
import {
	useCreateProjectMutation,
	useGetAllClientsQuery,
} from "../../../api/network/projectApiService"
import { routeUrls } from "../../../routes/routeUrls"
import { toast } from "react-toastify"
import "./Project.scss"
import useUserRole from "../../../hooks/useUserRole"
import { UserRole } from "../../../api/types/Auth"
import useCompaniesListOption from "../../../hooks/useCompaniesListOption"

const CreateProject = () => {
	const { t } = useTranslation("translation", { keyPrefix: "createProject" })

	const navigate = useNavigate()
	const appUserRole = useUserRole()

	const { companiesOptions, isFetching } = useCompaniesListOption()

	const { data: clientsData = [] } = useGetAllClientsQuery(null)

	const [createProjectTrigger, { isLoading: isCreatingProject }] =
		useCreateProjectMutation()

	const clientsOptions = useMemo(() => {
		return clientsData.map((c) => ({ id: c.client, label: c.client }))
	}, [clientsData])

	const handleCreateProject = (values: ICreateProjectFields) => {
		const startDateTime = dateFnsFormat(
			new Date(values?.startDate ?? ""),
			"yyyy-MM-dd hh:mm:ss",
		)
		const endDateTime = dateFnsFormat(
			new Date(values?.endDate ?? ""),
			"yyyy-MM-dd hh:mm:ss",
		)
		createProjectTrigger({
			company: values.company as number,
			name: values.projectName,
			description: values.description,
			start_date: startDateTime,
			end_date: endDateTime,
			client: values.projectClient,
			budget: values.projectBudget.toString(),
		})
			.unwrap()
			.then((res) => {
				console.log("response from create project", res)
				toast.success(t("project_created_success"))
				navigate(routeUrls.projects.list)
			})
			.catch((err) => {
				console.log("Error while creating project", err?.data?.error)
				if (
					err?.data?.error ===
					"name: Ensure this field has no more than 75 characters."
				) {
					return toast.error("Project name should not exceed 75 characters")
				} else {
					toast.error(t("project_created_error"))
				}
			})
	}

	const handleCancelClick = () => {
		navigate(routeUrls.projects.list)
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		setFieldValue,
	} = useFormik({
		initialValues: CreateProjectFormInitialValues,
		onSubmit: handleCreateProject,
		validationSchema: CreateProjectValidationSchema,
	})

	useEffect(() => {
		if (appUserRole === UserRole.CompanyAdmin) {
			companiesOptions[0]?.id &&
				setFieldValue("company", companiesOptions[0]?.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appUserRole])

	return (
		<form onSubmit={handleSubmit} className="create-project-section">
			<div className="heading-wrapper">
				<Typography className="title">{t("add_project")}</Typography>
				<Box display="flex" gap="16px">
					<AppButton
						onClick={handleCancelClick}
						btnSize="medium"
						variant="outlined"
						color="error"
					>
						{t("cancel")}
					</AppButton>
					<AppButton
						type="submit"
						btnSize="medium"
						disabled={isCreatingProject}
					>
						{t("create_project")}
					</AppButton>
				</Box>
			</div>
			<div className="project-create-form-container">
				<Typography className="subtitle">
					{t("general_project_details")}
				</Typography>
				<Grid2 container spacing={2}>
					<Grid2 size={6}>
						<AppAutocompleteFormField
							label={t("company")}
							isRequired
							disabled={isFetching || appUserRole === UserRole.CompanyAdmin}
							name="company"
							placeholder={t("select_company")}
							value={values.company}
							onChange={setFieldValue}
							onBlur={() => {
								handleBlur({ target: { name: "company" } })
							}}
							touched={touched.company}
							errorMsg={errors.company ? t(errors.company) : ""}
							options={companiesOptions}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("project_name")}
							isRequired
							name="projectName"
							placeholder={t("enter_project_name")}
							value={values.projectName}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.projectName}
							errorMsg={errors.projectName ? t(errors.projectName) : ""}
						/>
					</Grid2>
				</Grid2>
				<Grid2 container>
					<Grid2 size={12}>
						<AppFormField
							label={t("project_description")}
							name="description"
							placeholder={t("provide_project_description")}
							value={values.description}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.description}
							errorMsg={errors.description ? t(errors.description) : ""}
						/>
					</Grid2>
				</Grid2>
				<Grid2 container spacing={2}>
					<Grid2 size={6}>
						<AppFormField
							label={t("start_date")}
							isRequired
							type="date"
							name="startDate"
							placeholder={t("select_date")}
							value={values?.startDate || ""}
							onChange={(e) => {
								if (e.target.value) {
									handleChange(e)
								}
							}}
							onBlur={handleBlur}
							touched={touched.startDate}
							errorMsg={errors.startDate ? t(errors.startDate) : ""}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("end_date")}
							isRequired
							type="date"
							name="endDate"
							placeholder={t("select_date")}
							value={values?.endDate || ""}
							onChange={(e) => {
								if (e.target.value) {
									handleChange(e)
								}
							}}
							onBlur={handleBlur}
							touched={touched.endDate}
							errorMsg={errors.endDate ? t(errors.endDate) : ""}
						/>
					</Grid2>
				</Grid2>
				<Grid2 container spacing={2}>
					<Grid2 size={6}>
						<AppAutocompleteFormField
							label={t("project_client")}
							isRequired
							// freeSolo={true}
							// autoSelect
							name="projectClient"
							placeholder={t("select_client")}
							value={values.projectClient}
							onChange={setFieldValue}
							// onBlur={handleBlur}
							onBlur={() => {
								handleBlur({ target: { name: "projectClient" } })
							}}
							touched={touched.projectClient}
							errorMsg={errors.projectClient ? t(errors.projectClient) : ""}
							options={clientsOptions}
						/>
					</Grid2>
					<Grid2 size={6}>
						<AppFormField
							label={t("project_budget")}
							isRequired
							type="number"
							name="projectBudget"
							placeholder={t("enter_numeric_value")}
							value={values.projectBudget}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.projectBudget}
							errorMsg={errors.projectBudget ? t(errors.projectBudget) : ""}
						/>
					</Grid2>
				</Grid2>
			</div>
		</form>
	)
}

export default CreateProject
