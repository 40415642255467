import { format } from "date-fns"

/**
 * Method to format date in "DD MMM YYYY" format
 * @param date Date in string format
 * @returns formatted date in "DD MMM YYYY" format
 */
export const getDateFromTimestamp = (
	date: string,
	dateTimeFormat = "dd MMM yyyy",
) => {
	return format(new Date(date), dateTimeFormat)
}

export const formatDateWithMonthName = (date: string) => {
	const dt = new Date(date)
	if(dt.toString() === "Invalid Date") return date;

	return format(dt, "MMM dd, yyyy")
}