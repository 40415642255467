import TextField, { TextFieldProps } from "@mui/material/TextField"

const AppInput = ({
	placeholder,
	slotProps,
	sx,
	className = "",
	...props
}: TextFieldProps) => {
	return (
		<TextField
			fullWidth
			className={`field-input ${className}`}
			placeholder={placeholder}
			sx={{
				"& .MuiOutlinedInput-notchedOutline": {
					border: props.disabled ? "none" : "1px solid #D9D9D9",
				},
				"& .MuiFormHelperText-root": {
					marginLeft: 0,
				},
				...sx,
			}}
			slotProps={{
				...slotProps,
				input: {
					...(slotProps?.input || {}),
					style: {
						borderRadius: "6px",
						backgroundColor: props.disabled ? "#E6E6E6" : "#FAFAFA",
						...(((slotProps?.input as any) || {}).style || {}),
					},
				},
				htmlInput: {
					...(slotProps?.htmlInput || {}),
					style: {
						paddingTop: "12px",
						paddingBottom: "12px",
						fontSize: "14px",
						lineHeight: "20px",
						letterSpacing: "-0.15px",
						...(((slotProps?.htmlInput as any) || {}).style || {}),
					},
				},
			}}
			{...props}
		/>
	)
}

export default AppInput
