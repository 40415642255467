import { Box, Grid2 } from "@mui/material"
import "./AddActivity.scss"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import { IAddActivitySectionFormProps } from "./validation"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"
import {
	useGetAllWbsQuery,
	useGetProjectMembersQuery,
} from "../../api/network/projectApiService"
import { useEffect, useMemo } from "react"
import { ProjectUserRole } from "../../api/types/Auth"

interface IActivityGeneralDetailsFormProps
	extends IAddActivitySectionFormProps {
	projectId: number
}

export default function ActivityGeneralDetailsForm({
	projectId,
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	handleBlur,
	disabled,
}: IActivityGeneralDetailsFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { data: { results: wbsData } = {}, isFetching: isFetchingWbs } =
		useGetAllWbsQuery({
			project: projectId,
			page_size: 1000,
		})

	const {
		data: { results: projectMembers = [] } = {},
		isFetching: isFetchingPrjMembers,
	} = useGetProjectMembersQuery({
		id: projectId,
		page_size: 1000,
		page: 1,
	})

	const { wbsCodeOptions, wbsNameOptions } = useMemo(() => {
		const wbsCodeOptions =
			wbsData?.map((wbs) => ({
				id: wbs.wbs_code,
				label: wbs.wbs_code,
			})) || []
		const wbsNameOptions =
			wbsData?.map((wbs) => ({
				id: wbs.wbs_name,
				label: wbs.wbs_name,
			})) || []
		return { wbsCodeOptions, wbsNameOptions }
	}, [wbsData])

	const assigneeOptions = useMemo(() => {
		const teamMembers = projectMembers.filter(
			(member) => member.role_name === ProjectUserRole.TeamMember,
		)
		return teamMembers.map((member) => ({
			id: member.user_id,
			label: member.user_name || "",
		}))
	}, [projectMembers])

	const handleWbsChange = (
		field: string,
		value: any,
		shouldValidate?: boolean,
	) => {
		if (field === "wbs_code") {
			setFieldValue("wbs_code", value, shouldValidate)
			const wbsName = wbsData?.find((wbs) => wbs.wbs_code === value)?.wbs_name
			wbsName && setFieldValue("wbs_name", wbsName, shouldValidate)
		} else if (field === "wbs_name") {
			setFieldValue("wbs_name", value, shouldValidate)
			const wbsCode = wbsData?.find((wbs) => wbs.wbs_name === value)?.wbs_code
			wbsCode && setFieldValue("wbs_code", wbsCode, shouldValidate)
		}
	}

	const handleAssigneeChange = (field: string, value: any) => {
		setFieldValue("assignee", value ? value.map((v: any) => v.id) : [])
	}

	// useEffect(() => {
	// 	if (values.wbs_code) {
	// 		handleWbsChange("wbs_code", values?.wbs_code, true)
	// 	}
	// 	if (values.wbs_name) {
	// 		setFieldValue("wbs_name", values.wbs_name, true)
	// 	}
	// 	if (values?.assignee) {
	// 		console.log("values?.assignee", values?.assignee)
	// 		values?.assignee?.map((assignee: any) => {
	// 			console.log("assignee", assignee.name)
	// 			setFieldValue("assignee", assignee?.name)
	// 		})
	// 	}
	// 	if (values?.activity_type) {
	// 		setFieldValue("activity_type", values.activity_type)
	// 	}
	// 	if (values?.activity_name) {
	// 		setFieldValue("activity_name", values.activity_name)
	// 	}
	// }, [values.wbs_code, values.wbs_name, values.assignee])

	return (
		<Box>
			<Box
				sx={{
					bgcolor: "white",
					border: "2px solid #fff",
					borderRadius: "12px",
					boxShadow: 4,
					padding: 3,
					display: "flex",
					flexDirection: "column",
					gap: "16px",
				}}
			>
				<Grid2 className="title">{t("general_details")}</Grid2>
				<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
					<Grid2 width={"100%"}>
						<AppFormField
							label={t("activity_type")}
							disabled
							name={"activityType"}
							placeholder={t("activity_type")}
							value={values?.activity_type}
						/>
					</Grid2>
					<Grid2 width={"100%"}>
						<AppFormField
							label={t("activity_name")}
							isRequired
							name={"activity_name"}
							placeholder={t("activity_name")}
							disabled={disabled}
							value={values.activity_name}
							onChange={disabled ? undefined : handleChange}
							onBlur={handleBlur}
							touched={touched.activity_name}
							errorMsg={errors.activity_name ? t(errors.activity_name) : ""}
						/>
					</Grid2>
				</Grid2>
				<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
					<Grid2 width={"100%"}>
						<AppAutocompleteFormField
							label={t("wbs_code")}
							isRequired
							showLoader={isFetchingWbs}
							name={"wbs_code"}
							placeholder={t("wbs_code")}
							disabled={disabled}
							value={values.wbs_code}
							options={wbsCodeOptions}
							onChange={disabled ? undefined : handleWbsChange}
							onBlur={handleBlur}
							touched={touched.wbs_code}
							errorMsg={errors.wbs_code ? t(errors.wbs_code) : ""}
						/>
					</Grid2>
					<Grid2 width={"100%"}>
						<AppAutocompleteFormField
							label={t("wbs_name")}
							isRequired
							showLoader={isFetchingWbs}
							name={"wbs_name"}
							disabled={disabled}
							placeholder={t("wbs_name")}
							value={values.wbs_name}
							options={wbsNameOptions}
							onChange={disabled ? undefined : handleWbsChange}
							onBlur={handleBlur}
							touched={touched.wbs_name}
							errorMsg={errors.wbs_name ? t(errors.wbs_name) : ""}
						/>
					</Grid2>
				</Grid2>
				<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
					<Grid2 width={"100%"}>
						<AppAutocompleteFormField
							label={t("assignee")}
							isRequired
							showLoader={isFetchingPrjMembers}
							name={"assignee"}
							placeholder={t("assignee")}
							value={values.assignee}
							disabled={disabled}
							options={assigneeOptions}
							multiple={true}
							onChange={disabled ? undefined : handleAssigneeChange}
							onBlur={handleBlur}
							touched={touched.wbs_name}
							errorMsg={errors.wbs_name ? t(errors.wbs_name) : ""}
						/>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	)
}
