import { Grid2, List, ListItem, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useState } from "react"
export default function SwimlaneItem({item}: any) {
	// console.log("item#",item)
	const [expanded, setExpanded] = useState(false)

    console.log(item.level)
	const toggleExpand = () => {
		setExpanded(!expanded)
	}

	return (
		<>
			<Grid2 display="flex" justifyContent="space-between" gap={2}>
				<Grid2 width="100%" display="flex" alignItems="center" gap={2}>
					<ListItem
                        className="asdf"
						component="div"
						onClick={toggleExpand}
						sx={{ pl: Number(item.level) * 2 }}
					>
						<List
							sx={{
								width: "100%",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
                            <Typography color="secondary" fontSize={16} sx={{
								width: "100%",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}>

							{item.wbs_name}
                            </Typography>
						</List>
						{item.children?.length > 0 &&
							(expanded ? <ExpandLessIcon color="secondary" /> : <ExpandMoreIcon color="secondary" />)}
					</ListItem>
				</Grid2>
			</Grid2>
			{expanded &&
				item.children.map((child: any) => (
					<SwimlaneItem key={child.wbs_code} item={child} />
				))}
		</>
	)
}
