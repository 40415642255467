import { Box, Button, Grid2, List, Typography, useTheme } from "@mui/material"
import {
	useLazyGetSwimlanesListByActivityCodeQuery,
	useLazyGetSwimlanesListByWbsQuery,
} from "../../api/network/projectApiService"
import { useState } from "react"
import SwimlaneItem from "./SwimlaneItem"
import { swimlane_activities_list, swimlane_wbs_list } from "./SwimlaneData"

export default function SwimlaneLists() {
	const [
		getSwimlanesByActivity,
		{ data: activityData, isFetching: isFetchingActivity },
	] = useLazyGetSwimlanesListByActivityCodeQuery()
	const [getSwimlanesByWBS, { data: wbsData, isFetching: isFetchingWBS }] =
		useLazyGetSwimlanesListByWbsQuery()
	const [showActivityList, setShowActivityList] = useState<boolean>(false)
	const [showWBSList, setShowWBSList] = useState<boolean>(false)
	const theme = useTheme()
	// Access custom theme properties
	const primaryColor = theme.palette.primary.main

	const handleSwimlaneListByWBS = () => {
		// getSwimlanesByWBS({
		//     page: 1,
		//     page_size: 200,
		//     project: 61
		// });
		// console.log("SwimLanes By WBS", wbsData?.results);
		setShowActivityList(false)
		setShowWBSList(!showWBSList)
	}

	const handleSwimlaneListByActivity = () => {
		// getSwimlanesByActivity({
		//     page: 1,
		//     page_size: 200,
		//     project: 61
		// });
		// console.log("SwimLanes By Activity", activityData?.results);
		setShowWBSList(false)
		setShowActivityList(!showActivityList)
	}

	return (
		<>
			<Box>
				<Grid2 display={"flex"} justifyContent="space-between" margin={2}>
					<Button variant={showWBSList ? "contained" : "outlined"} color="secondary" onClick={handleSwimlaneListByWBS}>
						By WBS
					</Button>
					<Button focusRipple={false} disableFocusRipple variant={showActivityList ? "contained" : "outlined"} color="secondary" onClick={handleSwimlaneListByActivity}>
						By Activity
					</Button>
				</Grid2>
				<Box
					sx={{
						bgcolor: primaryColor,
						display: "flex",
						flexDirection: "column",
					}}
				>
					{showActivityList &&
						swimlane_activities_list.results.map((swimlane, index) => (
							<Grid2
								key={index}
								display={"flex"}
								justifyContent={"space-between"}
								gap={2}
								pl={2}
							>
								<Grid2 width={"100%"}>
									<List key={swimlane?.actv_code_id}>
										<Typography
											color="secondary"
										>
											{swimlane.actv_code_name}
										</Typography>
									</List>
								</Grid2>
							</Grid2>
						))}

					{showWBSList &&
						swimlane_wbs_list.results.map((swimlane) => (
							<SwimlaneItem key={swimlane.wbs_code} item={swimlane} />
						))}
				</Box>
			</Box>
		</>
	)
}
