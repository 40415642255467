import React, { useEffect, useState } from "react"
import Timeline, {
	DateHeader,
	ReactCalendarItemRendererProps,
	TimelineHeaders,
	TimelineMarkers,
	TodayMarker,
} from "react-calendar-timeline"
import moment from "moment"
import Card from "../../components/Cards/Card"
import "react-calendar-timeline/lib/Timeline.css"
import "./Swimlane.scss"
import { groups, Item, items, items2 } from "./SwimlaneData"
import LineConnect from "../../components/LineConnect/LineConnect"
import { Icon, Typography } from "@mui/material"
import { Svgs } from "../../assets/svgs"

const customItemRenderer = ({
	item,
	itemContext,
	getItemProps,
	getResizeProps,
}: ReactCalendarItemRendererProps<Item>) => {
	const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

	return (
		<div
			id={item.id.toString()}
			{...getItemProps({
				style: {
					background: "white",
					border: "1px solid white",
					color: "black",
					position: "absolute",
					// zIndex: itemContext.stack ? itemContext.stack : 1,
				},
			})}
		>
			{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}
			{/* big card or small card condition will be implemented here*/}
			<Card {...item} />

			{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
		</div>
	)
}

const SwimlaneCalender = () => {
	const [renderKey, setRenderKey] = useState(0)
	const [cards, setCards] = useState(items)
	const [cards2, setCards2] = useState(items2)
	const [visibleSections, setVisibleSections] = useState([false, false])
	//will be calculated from real api data
	const minTime = moment().startOf("month").valueOf()
	const maxTime = moment().add(1, "month").startOf("month").valueOf()
	const minZoom = 5 * 24 * 60 * 60 * 1000
	const maxZoom = 30 * 24 * 60 * 60 * 1000
	// const colors = {
	// 	expandBg:'#7785CC',

	// }

	const [visibleTimeStart, setvisibleTimeStart] = useState(minTime)
	const [visibleTimeEnd, setvisibleTimeEnd] = useState(maxTime)

	const handleTimeChange = (start: any, end: any, updateScrollCanvas: any) => {
		let startTo
		let endTo

		if (start < minTime) {
			startTo = minTime
			endTo = minTime + (end - start)
			// updateScrollCanvas(minTime, minTime + (end - start))
		} else if (end > maxTime) {
			startTo = maxTime - (end - start)
			endTo = maxTime
			// updateScrollCanvas(maxTime - (end - start), maxTime)
		} else {
			startTo = start
			endTo = end
			// updateScrollCanvas(start, end)
		}
		setvisibleTimeStart(startTo)
		setvisibleTimeEnd(endTo)
		redrawLinks()
	}

	const onItemResize = (id: number, time: number) => {
		setCards((prevCards) =>
			prevCards.map((card) =>
				card.id === id ? { ...card, end_time: new Date(time) } : card,
			),
		)
	}

	const onItemMove = (id: number, time: number) => {
		setCards((prevCards) =>
			prevCards.map((card) =>
				card.id === id
					? {
							...card,
							start_time: new Date(time),
							end_time: new Date(
								time + card.end_time.getTime() - card.start_time.getTime(),
							),
						}
					: card,
			),
		)
	}

	const toggleVisibility = (index: number) => {
		setVisibleSections((prevState) => [
			...prevState.slice(0, index),
			!prevState[index],
			...prevState.slice(index + 1),
		])
	}
	const redrawLinks = () => {
		setRenderKey((prevKey) => prevKey + 1)
	}

	useEffect(()=>{
		redrawLinks()
	}, [visibleSections])



	return (
		<div className="swimlane-calender-container">
			<Timeline
				stackItems={true}
				groups={groups}
				items={[]}
				defaultTimeStart={moment(minTime)}
				defaultTimeEnd={moment(maxTime)}
				visibleTimeStart={visibleTimeStart}
				visibleTimeEnd={visibleTimeEnd}
				itemRenderer={customItemRenderer}
				sidebarWidth={0}
				canMove
				canResize
				// onBoundsChange={(...x) => {
				// 	redrawLinks()
				// }}
				onZoom={(...x) => {
					redrawLinks()
				}}
				minZoom={minZoom}
				maxZoom={maxZoom}
				onTimeChange={handleTimeChange}
				onItemResize={onItemResize}
				onItemMove={onItemMove}
			>
				<TimelineMarkers>
					<TodayMarker date={new Date()} />
				</TimelineMarkers>
				<TimelineHeaders>
					<DateHeader
						style={{ backgroundColor: "#215B90", color: "white" }}
						unit="primaryHeader"
					/>
					<DateHeader
						unit="day"
						style={{
							color: "white",
						}}
						labelFormat="DD"
					/>
				</TimelineHeaders>
			</Timeline>
			<div
				className="expand"
				onClick={() => {
					toggleVisibility(0)
				}}
			>
				<Typography color="white">Floor1</Typography>
				<Svgs.ExpandIcon />
			</div>
			{visibleSections[0] && (
				<Timeline
					stackItems={true}
					groups={groups}
					items={cards}
					defaultTimeStart={moment(minTime)}
					defaultTimeEnd={moment(maxTime)}
					visibleTimeStart={visibleTimeStart}
					visibleTimeEnd={visibleTimeEnd}
					itemRenderer={customItemRenderer}
					sidebarWidth={0}
					canMove
					canResize
					// onBoundsChange={(...x) => {
					// 	redrawLinks()
					// }}
					onZoom={(...x) => {
						redrawLinks()
					}}
					minZoom={minZoom}
					maxZoom={maxZoom}
					onTimeChange={handleTimeChange}
					onItemResize={onItemResize}
					onItemMove={onItemMove}
				>
					<TimelineMarkers>
						<TodayMarker date={new Date()} />
					</TimelineMarkers>
					<TimelineHeaders>
						{/* <DateHeader
						style={{ backgroundColor: "#215B90", color: "white" }}
						unit="primaryHeader"
					/>
					<DateHeader
						unit="day"
						style={{
							color: "white",
						}}
						labelFormat="DD"
					/> */}
						<></>
					</TimelineHeaders>
				</Timeline>
			)}
			<div
				className="expand"
				onClick={() => {
					toggleVisibility(1)
				}}
			>
				<Typography color="white">Floor1</Typography>
				<Svgs.ExpandIcon />
			</div>
			{visibleSections[1] && (
				<Timeline
					stackItems={true}
					groups={groups}
					items={cards2}
					visibleTimeStart={visibleTimeStart}
					visibleTimeEnd={visibleTimeEnd}
					defaultTimeStart={moment(minTime)}
					defaultTimeEnd={moment(maxTime)}
					itemRenderer={customItemRenderer}
					sidebarWidth={0}
					canMove
					canResize
					onBoundsChange={(...x) => {
						console.log("as", x)
						redrawLinks()
					}}
					onZoom={(...x) => {
						console.log("ay", x)
						redrawLinks()
					}}
					minZoom={minZoom}
					maxZoom={maxZoom}
					onTimeChange={handleTimeChange}
					onItemResize={onItemResize}
					onItemMove={onItemMove}
				>
					<TimelineMarkers>
						<TodayMarker date={new Date()} />
					</TimelineMarkers>
					<TimelineHeaders>
						{/* <DateHeader
							style={{ backgroundColor: "#215B90", color: "white" }}
							unit="primaryHeader"
						/>
						<DateHeader
							unit="day"
							style={{
								color: "white",
							}}
							labelFormat="DD"
						/> */}
						<></>
					</TimelineHeaders>
				</Timeline>
			)}

			<div
				key={renderKey}
				className="aaa"
				// style={{ position: "relative", zIndex: 20 }}
			>
				{visibleSections[0] && (
					<LineConnect
						lines={cards.map((card) => {
							const { id, linkedTo, linkType } = card
							return {
								id,
								linkedTo,
								linkType,
							}
						})}
					/>
				)}
				{visibleSections[1] && (
					<LineConnect
						lines={cards2.map((card) => {
							const { id, linkedTo, linkType } = card
							return {
								id,
								linkedTo,
								linkType,
							}
						})}
					/>
				)}
			</div>
		</div>
	)
}

export default SwimlaneCalender
