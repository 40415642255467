import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoginResponseSuccess } from "../types/Auth"

const initialState: LoginResponseSuccess | null = null

const authSlice = createSlice({
	name: "authData",
	reducerPath: "authData",
	initialState,
	reducers: {
		updateAuthData(state, action: PayloadAction<LoginResponseSuccess>) {
			if (action.payload.token) {
				state = action.payload as any
			}
			return state
		},
		clearAuthData() {
			return null
		},
	},
})

// Export actions
export const { updateAuthData, clearAuthData } = authSlice.actions

// Export the reducer
export default authSlice
