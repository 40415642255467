import * as Yup from "yup"
import { TProjectMember } from "../../../api/types/Project"
import { TRole } from "../../../api/types/Roles"

export interface ICreateProjectFields {
	company: number | null
	projectName: string
	description: string
	startDate: string
	endDate: string
	projectClient: string
	projectBudget: number
}

export const CreateProjectFormInitialValues: ICreateProjectFields = {
	company: null,
	projectName: "",
	description: "",
	startDate: "",
	endDate: "",
	projectClient: "",
	projectBudget: 0,
}

export const CreateProjectValidationSchema = Yup.object().shape({
	company: Yup.number()
		.required("errors.company_required")
		.nonNullable("errors.company_required"),
	projectName: Yup.string().required("errors.project_required"),
	startDate: Yup.date().required("errors.start_date_required"),
	endDate: Yup.date()
		.required("errors.end_date_required")
		.min(Yup.ref("startDate"), "errors.end_less_than_start"),
	projectClient: Yup.string().required("errors.project_client_required"),
	projectBudget: Yup.number()
		.required()
		.moreThan(0, "errors.project_budget_required"),
})

export interface IUpdateProjectFields {
	projectName: string
	description: string
	startDate: string
	endDate: string
	projectBudget: number
}

export const updateProjectFormInitialValues: IUpdateProjectFields = {
	projectName: "",
	description: "",
	startDate: "",
	endDate: "",
	projectBudget: 0,
}

export const UpdateProjectValidationSchema = Yup.object().shape({
	projectName: Yup.string().required("errors.project_required"),
	startDate: Yup.date().required("errors.start_date_required"),
	endDate: Yup.date()
		.required("errors.end_date_required")
		.min(Yup.ref("startDate"), "errors.end_less_than_start"),
	projectBudget: Yup.number()
		.required()
		.moreThan(0, "errors.project_budget_required"),
})

export interface ITeamMember {
	user: string | number
	role: string | number
}

export const AddTeamMemberInitialValue: ITeamMember = {
	user: "",
	role: "",
}

export const AddTeamMemberValidationSchema = Yup.object().shape({
	user: Yup.number().required("errors.select_user"),
	role: Yup.number().required("errors.select_role"),
})

export interface ITeamMemberListItemProps {
	projectId: number
	user: TProjectMember
	roles: TRole[]
	updateApiTriger: any
}
