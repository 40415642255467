import { Box, Grid2 } from "@mui/material"
import { useTranslation } from "react-i18next"
import AppFormField from "../../components/AppFormField/AppFormField"
import { IAddSubActivitySectionFormProps } from "./validation"
export default function SubActivityTrade(
	{
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		disabled,
		handleBlur,
	}: IAddSubActivitySectionFormProps
) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "subActivity.errors",
	})
	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 display={"flex"} justifyContent={"space-between"}>
				<Grid2 className="title">{t("trade")}</Grid2>
			</Grid2>
			<Grid2>
				<AppFormField
					label={t("trade_responsible")}
					isRequired
					name={"trade"}
					placeholder={t("select_trade")}
					value={values.trade}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.trade}
					disabled={disabled}
					errorMsg={errors.trade ? tErrors('trade_responsible') : ""}
				/>
			</Grid2>
		</Box>
	)
}
