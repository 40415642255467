import * as Yup from "yup"
import { EMAIL_REGEX, phoneRegex } from "../../constants/constants"
import { TranslationProps, UseTranslationResponse } from "react-i18next"
import { TFunction } from "i18next"

export interface IAddCompanydFields {
	companyId: string
	companyName: string
	companyAddress: string
	email: string
	phoneNumber: string
	logo: File | string | undefined
}

export const addCompanyFormInitialValues: IAddCompanydFields = {
	companyId: "",
	companyName: "",
	companyAddress: "",
	email: "",
	phoneNumber: "",
	logo: undefined,
}

export const addCompanyValidationSchema = (t: TFunction) =>
	Yup.object().shape({
		companyId: Yup.string().required("errors.company_id_required"),
		companyName: Yup.string().required("errors.company_name_required").max(50, "errors.company_name_length"),
		// companyAddress: Yup.string().required("errors.company_address_required"),
		email: Yup.string()
			.matches(EMAIL_REGEX, "errors.invalid_email")
			.required("errors.email_required"),
		// phoneNumber: Yup.string().required("errors.phone_number_required"),
		phoneNumber: Yup.string()
		.trim()
		.matches(phoneRegex, "Invalid phone number")
		.required("Phone number is required"),
		logo: Yup.string().required(t("logo_required")),
	})
