import { Box, Grid2, Menu, MenuItem, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import SearchInput from "../../../components/AppSearchBox/AppSearchBox"
import AppButton from "../../../components/AppButton/AppButton"
import AppNavBar from "../../../components/AppNavBar/AppNavBar"
import { ExpandMoreOutlined } from "@mui/icons-material"
import "./FeedbackList.scss"
import DisplayTable from "../../../components/DisplayTable/DisplayTable"
import { useTranslation } from "react-i18next"
import {useDebounce } from "../../../constants/constants"
import ViewDetailButton from "../../../components/DisplayTable/ViewDetailButton"
import TableCellText from "../../../components/DisplayTable/TableCellText"
import { FeedbackStatus } from "../../../utils"
import { useGetAllFeedbacksQuery } from "../../../api/network/projectApiService"
import useAppPagination from "../../../hooks/useAppPagination"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../../routes/routeUrls"
export default function FeedbackList() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [searchTerm, setSearchTerm] = useState("")
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")
	const open = Boolean(anchorEl)
	const { t } = useTranslation("translation", { keyPrefix: "feedback" })
	const navigate=useNavigate()

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()
	const { data, isFetching } = useGetAllFeedbacksQuery({
		page_size: pageRowsCount,
		page: currentPage,
	})

	console.log("data#", data)
	const handleRowClick=(data:any)=>{
		console.log("RowData",data)
		navigate(routeUrls.feedback_detail,{state:{userData:data}})
	}

	const tableHeaders = useMemo(
		() => [
			{ name: "User Name", sortable: false },
			{ name: "Company", sortable: false },
			{ name: "Email", sortable: false },
			{ name: "Type", sortable: false },
			{ name: "Submission Date", sortable: false },
			{ name: "Status", sortable: false },
		],
		[],
	)
	const columns = useMemo(
		() => [
			"user_name",
			"company_name",
			"user_email",
			"feedback_type",
			"created_at",
			(data: any) => (
				<TableCellText
					sx={{
						color: FeedbackStatus.find(
							(statusObj) => statusObj.status === data.status,
						)?.color,
					}}
				>
					{data.status}
				</TableCellText>
			),
			() => <ViewDetailButton />,
		],
		[],
	)

	const handleClickLabel = (label: string) => {
		console.log("Label Clicked", label)
	}

	const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Search Term", event.target.value)
		debouncedHandleSearchChange(event.target.value)
	}

	useEffect(() => {
		setDebouncedSearchTerm(searchTerm)
	}, [searchTerm])

	// Filtering results based on debounced search term
	// const filteredResults = filterData(data?.results, debouncedSearchTerm)

	return (
		<Box width={"100%"} className={"feedback-list-container"}>
			<Box display={"flex"} marginTop={2} marginLeft={2} marginRight={2}>
				<Grid2>
					<Typography variant={"h4"} marginBottom={2}>
						{t("title")}
					</Typography>
					<Box display={"flex"} justifyContent={"space-between"} gap={3}>
						<Grid2 width={"50%"}>
							<SearchInput
								value={searchTerm}
								onChange={handleSearchChange}
								placeholder="Search by user name"
							/>
						</Grid2>
						<div className="status-filter-wrapper">
							<AppButton
								onClick={() => {
									console.log("")
								}}
								endIcon={<ExpandMoreOutlined />}
							>
								{t("company")}
							</AppButton>
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClick={() => {
									console.log("")
								}}
							>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"all"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"active"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"suspended"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"removed"}
								</MenuItem>
							</Menu>
						</div>
						<div className="status-filter-wrapper">
							<AppButton
								onClick={() => {
									console.log("")
								}}
								endIcon={<ExpandMoreOutlined />}
							>
								{t("status")}
							</AppButton>
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClick={() => {
									console.log("")
								}}
							>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"all"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"active"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"suspended"}
								</MenuItem>
								<MenuItem
									onClick={() => {
										console.log("")
									}}
								>
									{"removed"}
								</MenuItem>
							</Menu>
						</div>

						<Box width={"100%"} marginBottom={1}>
							<AppNavBar
								options={["All", "Feedback", "Suggestion", "Issue"]}
								handleClickLabel={handleClickLabel}
								defaultSelected={"All"}
								marginBottom={false}
							/>
						</Box>
					</Box>
					<Grid2>
						<DisplayTable
							isFetching={isFetching}
							tableHeaders={tableHeaders}
							results={data?.results}
							tableColumns={columns}
							pageRowsCount={pageRowsCount}
							onPageCountChange={handlePageCountChange}
							totalPages={1}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							onRowClick={handleRowClick}
							totalPageCount={data?.count}
						/>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	)
}
