import { useTranslation } from "react-i18next"
import {
	Avatar,
	Box,
	Grid2,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import "./Project.scss"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { format as dateFnsFormat } from "date-fns"
import AppButton from "../../../components/AppButton/AppButton"
import AppFormField from "../../../components/AppFormField/AppFormField"
import {
	ITeamMemberListItemProps,
	IUpdateProjectFields,
	updateProjectFormInitialValues,
	UpdateProjectValidationSchema,
} from "./validation"
import {
	useGetProjectDetailsQuery,
	useUpdateProjectMutation,
	useUpdateUserRoleForProjectMutation,
} from "../../../api/network/projectApiService"
import { routeUrls } from "../../../routes/routeUrls"
import { toast } from "react-toastify"
import AppLoader from "../../../components/AppLoader/AppLoader"
import { AppColors } from "../../../theme"
import { ExpandMoreOutlined } from "@mui/icons-material"
import {
	getProjectStatusColor,
	getUserInitials,
	projectStatusArray,
} from "../../../utils"
import { Svgs } from "../../../assets/svgs"
import AddTeamMemberModal from "./AddTeamMemberModal"
import { useSelector } from "react-redux"
import { limitedRolesSelector } from "../../../api/network/rolesApiService"
import DetailNavBar from "../../../components/DetailNavBar/DetailNavBar"

const TeamMemberListItem = ({
	projectId,
	user,
	roles = [],
	updateApiTriger,
}: ITeamMemberListItemProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const openRolesMenu = Boolean(anchorEl)

	const { t } = useTranslation("translation", {
		keyPrefix: "viewAndEditProject",
	})

	const rolesForUser = useMemo(() => {
		return roles.filter((r) => r.role !== user.role_name)
	}, [roles, user.role_name])

	const handleRolesMenuOpen = (event: React.MouseEvent<any>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleRolesMenuClose = () => {
		setAnchorEl(null)
	}

	const handleRoleMenuChange = (roleId: number) => () => {
		console.log("Changed role", roleId)
		handleRolesMenuClose()
		updateApiTriger({
			user_id: user.user_id,
			role_id: roleId,
			project_id: projectId,
		})
			.unwrap()
			.then(() => {
				toast.success(t("role_update_success"))
			})
			.catch((err: any) => {
				console.log("Error while updating user role", err)
				toast.error(t("role_update_error"))
			})
	}

	return (
		<Box key={user.user_email} display="flex" gap="6px">
			<Avatar
				variant="rounded"
				sx={{
					background: AppColors.secondary,
					width: "32px",
					height: "32px",
					fontSize: "16px",
					fontWeight: "500",
					lineHeight: "19.54px",
				}}
			>
				{getUserInitials(user.user_name || "")}
			</Avatar>
			<Box display="flex" flexDirection="column" gap="4px">
				<Typography className="user-name">{user.user_name || " "}</Typography>
				<Box display="flex" alignItems="center" height="12px">
					<Typography className="user-role">{user.role_name}</Typography>
					<ExpandMoreOutlined
						className="expand-icon"
						onClick={handleRolesMenuOpen}
					/>
					<Menu
						anchorEl={anchorEl}
						open={openRolesMenu}
						onClose={handleRolesMenuClose}	
					>
						{rolesForUser.map((m) => (
							<MenuItem key={m.id} onClick={handleRoleMenuChange(m.id)}>
								{m.role}
							</MenuItem>
						))}
					</Menu>
				</Box>
			</Box>
		</Box>
	)
}

const ViewAndEditProject = () => {
	const { id: projectIdStr = "" } = useParams()
	const projectId = useMemo(() => {
		return isNaN(Number(projectIdStr)) ? 0 : Number(projectIdStr)
	}, [projectIdStr])

	const {
		data: projectData,
		isFetching,
		isError,
		error,
		isLoading,
	} = useGetProjectDetailsQuery(projectId.toString())

	const [isEditing, setIsEditing] = useState(false)
	const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const { t: tCreatePrj } = useTranslation("translation", {
		keyPrefix: "createProject",
	})
	const { t } = useTranslation("translation", {
		keyPrefix: "viewAndEditProject",
	})

	const [updateProjectTrigger, { isLoading: isUpdatingProject }] =
		useUpdateProjectMutation()

	const [updateUserRoleTrigger, { isLoading: isUpdatingUserRole }] =
		useUpdateUserRoleForProjectMutation()

	const { data: limitedRolesData = [] } = useSelector(limitedRolesSelector)

	const navigate = useNavigate()

	const disableEditing = () => {
		setIsEditing(false)
	}

	const handleCancelClick = () => {
		disableEditing()
	}

	const enableEditing = () => {
		setIsEditing(true)
	}

	const handleBackClick = () => {
		navigate(routeUrls.projects.list)
	}

	const handleUpdateProject = (values: IUpdateProjectFields) => {
		const startDateTime = dateFnsFormat(
			new Date(values?.startDate ?? ""),
			"yyyy-MM-dd hh:mm:ss",
		)
		const endDateTime = dateFnsFormat(
			new Date(values?.endDate ?? ""),
			"yyyy-MM-dd hh:mm:ss",
		)
		updateProjectTrigger({
			id: projectId,
			name: values.projectName,
			description: values.description,
			start_date: startDateTime,
			end_date: endDateTime,
			budget: values.projectBudget.toString(),
		})
			.unwrap()
			.then((res) => {
				console.log("response from update project", res)
				toast.success(t("project_updated_success"))
				disableEditing()
			})
			.catch((err) => {
				console.log("Error while creating project", err)
				toast.error(t("project_update_error"))
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		setValues,
	} = useFormik({
		initialValues: updateProjectFormInitialValues,
		onSubmit: handleUpdateProject,
		validationSchema: UpdateProjectValidationSchema,
	})

	useEffect(() => {
		if (!isFetching) {
			if (isError) {
				const _error = (error as any) || {}
				if (_error.status === 404) {
					toast.error(t("errors.not_found"))
					navigate(routeUrls.projects.list)
				}
			} else if (projectData) {
				setValues({
					projectName: projectData.name,
					description: projectData.description,
					startDate: dateFnsFormat(
						new Date(projectData.start_date),
						"yyyy-MM-dd",
					),
					endDate: dateFnsFormat(new Date(projectData.end_date), "yyyy-MM-dd"),
					projectBudget: Number(projectData.budget || 0),
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData, isFetching, isError])

	const projectStatusList = useMemo(() => {
		return projectStatusArray.filter(
			(status) => status.status !== projectData?.status,
		)
	}, [projectData?.status])

	const handleProjectStatusMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		setAnchorEl(event.currentTarget)
	}
	const handleProjectStatusMenuClose = () => {
		setAnchorEl(null)
	}

	const handleProjectStatusMenuChange = (status: string) => () => {
		updateProjectTrigger({
			id: projectId,
			status: status,
		})
			.unwrap()
			.then(() => {
				toast.success(t("status_updated_success"))
			})
			.catch((err) => {
				console.log("Error while updating project status", err)
				toast.error(t("status_updated_error"))
			})
		handleProjectStatusMenuClose()
	}

	const handleOpenAddMemberModal = () => {
		setOpenAddMemberModal(true)
	}

	const closeAddMemberModal = () => {
		setOpenAddMemberModal(false)
	}

	const renderProjectStatus = () => {
		const _color = getProjectStatusColor(projectData?.status || "")
		return (
			<>
				<AppButton
					onClick={handleProjectStatusMenuOpen}
					variant="text"
					// endIcon={<ExpandMoreOutlined />}
					sx={{
						color: _color,
					}}
					startIcon={<Svgs.ProjectStatusLogo color={_color} />}
				>
					{projectData?.status}
				</AppButton>
				{/* <Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleProjectStatusMenuClose}
				>
					{projectStatusList.map((m) => (
						<MenuItem
							key={m.status}
							onClick={handleProjectStatusMenuChange(m.status)}
						>
							{m.status}
						</MenuItem>
					))}
				</Menu> */}
			</>
		)
	}

	const renderHeaderButtons = () => {
		if (isEditing) {
			return (
				<>
					<AppButton
						sx={{
							width: "96px",
						}}
						onClick={handleCancelClick}
						btnSize="medium"
						variant="outlined"
						color="error"
					>
						{t("cancel")}
					</AppButton>
					<AppButton
						sx={{
							width: "96px",
						}}
						type="submit"
						btnSize="medium"
						color="success"
						style={{
							color: "white",
						}}
						disabled={isUpdatingProject}
					>
						{t("save")}
					</AppButton>
				</>
			)
		} else {
			return (
				<AppButton onClick={enableEditing} btnSize="medium">
					{t("edit_project")}
				</AppButton>
			)
		}
	}

	const renderTeamMembers = () => {
		if (!projectData?.project_members?.length) {
			return (
				<Box className="no-members-section">
					<Typography className="no-members-text">{t("no_members")}</Typography>
					<Typography className="add-info-text">
						{t("add_superintendents")}
					</Typography>
				</Box>
			)
		} else {
			return projectData.project_members.map((m) => (
				<TeamMemberListItem
					key={m.user_email}
					user={m}
					projectId={projectId}
					roles={limitedRolesData}
					updateApiTriger={updateUserRoleTrigger}
				/>
			))
		}
	}

	if (isLoading) {
		return <AppLoader open />
	}

	return (
		<>
			{isUpdatingProject && <AppLoader open />}
			<form onSubmit={handleSubmit} className="update-project-section">
				<DetailNavBar
					title={projectData?.name ?? ""}
					subTitle={t("project_id", { id: projectId })}
					onBackPress={handleBackClick}
					rightView={
						<Box display="flex" gap="16px" alignItems="center">
							{renderProjectStatus()}
							{renderHeaderButtons()}
						</Box>
					}
				/>
				<Box display="flex">
					<div className="project-update-form-container">
						<Typography className="subtitle">
							{tCreatePrj("general_project_details")}
						</Typography>
						<Grid2 container spacing={2}>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("company")}
									name="company"
									placeholder=""
									value={projectData?.company_name}
									inputProps={{
										disabled: true,
									}}
								/>
							</Grid2>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("project_name")}
									isRequired
									name="projectName"
									placeholder={tCreatePrj("enter_project_name")}
									value={values.projectName}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.projectName}
									errorMsg={
										errors.projectName ? tCreatePrj(errors.projectName) : ""
									}
									inputProps={{
										disabled: !isEditing,
									}}
								/>
							</Grid2>
						</Grid2>
						<Grid2 container>
							<Grid2 size={12}>
								<AppFormField
									label={tCreatePrj("project_description")}
									name="description"
									placeholder={tCreatePrj("provide_project_description")}
									value={values.description}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.description}
									errorMsg={
										errors.description ? tCreatePrj(errors.description) : ""
									}
									inputProps={{
										disabled: !isEditing,
									}}
								/>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2}>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("start_date")}
									isRequired
									type="date"
									name="startDate"
									placeholder={tCreatePrj("select_date")}
									value={values?.startDate || ""}
									onChange={(e) => {
										if (e.target.value) {
											handleChange(e)
										}
									}}
									onBlur={handleBlur}
									touched={touched.startDate}
									errorMsg={
										errors.startDate ? tCreatePrj(errors.startDate) : ""
									}
									inputProps={{
										disabled: !isEditing,
									}}
								/>
							</Grid2>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("end_date")}
									isRequired
									type="date"
									name="endDate"
									placeholder={tCreatePrj("select_date")}
									value={values?.endDate || ""}
									onChange={(e) => {
										if (e.target.value) {
											handleChange(e)
										}
									}}
									onBlur={handleBlur}
									touched={touched.endDate}
									errorMsg={errors.endDate ? tCreatePrj(errors.endDate) : ""}
									inputProps={{
										disabled: !isEditing,
									}}
								/>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2}>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("project_client")}
									name="projectClient"
									placeholder=""
									value={projectData?.client}
									inputProps={{
										disabled: true,
									}}
								/>
							</Grid2>
							<Grid2 size={6}>
								<AppFormField
									label={tCreatePrj("project_budget")}
									isRequired
									type="number"
									name="projectBudget"
									placeholder={tCreatePrj("enter_numeric_value")}
									value={values.projectBudget}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.projectBudget}
									errorMsg={
										errors.projectBudget ? tCreatePrj(errors.projectBudget) : ""
									}
									inputProps={{
										disabled: !isEditing,
									}}
								/>
							</Grid2>
						</Grid2>
					</div>
					<div className="team-members-container">
						<div className="team-members-section">
							<Typography className="title">{t("member_details")}</Typography>
							<Box display="flex" flexDirection="column" gap="16px">
								<Typography className="subtitle">
									{t("team_members")}
								</Typography>
								{renderTeamMembers()}
								<Typography
									className="add-member-action-text"
									onClick={handleOpenAddMemberModal}
								>
									{t("add_team_member")}
								</Typography>
							</Box>
						</div>
						{!projectData?.project_members?.length && (
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								gap="12px"
								mt="32px"
							>
								<Typography
									fontWeight="700"
									fontSize="14px"
									lineHeight="17px"
									color="primary"
								>
									{t("want_invite_user")}
								</Typography>
								<Typography
									fontSize="10px"
									fontWeight="400"
									lineHeight="14px"
									color="primary"
								>
									{t("go_to_add_user.first")}
									<b>{t("go_to_add_user.bold")}</b>
									{t("go_to_add_user.remaining")}
								</Typography>
							</Box>
						)}
					</div>
				</Box>
				<AddTeamMemberModal
					projectId={projectId}
					open={openAddMemberModal}
					onClose={closeAddMemberModal}
				/>
				{isUpdatingUserRole && <AppLoader open />}
			</form>
		</>
	)
}

export default ViewAndEditProject
