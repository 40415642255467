import { useMemo } from "react"
import { useGetAllCompaniesQuery } from "../api/network/companyApiService"
import useUserRole from "./useUserRole"
import { UserRole } from "../api/types/Auth"
import useAuthData from "./useAuthData"

const useCompaniesListOption = () => {
	const appUserRole = useUserRole()
	const authData = useAuthData()
	const { data: { results: companiesData = [] } = {}, isFetching } =
		useGetAllCompaniesQuery({
			page_size: 200,
			page: 1,
		})

	const companiesOptions = useMemo(() => {
		switch (appUserRole) {
			case UserRole.SuperAdmin:
				return companiesData.map((c) => ({ id: c.id, label: c.name }))
			case UserRole.CompanyAdmin:
				return authData?.user?.company_id
					? [
							{
								id: authData?.user?.company_id.toString(),
								label: authData?.user?.company_name || "",
							},
						]
					: []
			default:
				return []
		}
	}, [companiesData, appUserRole, authData])

	return { isFetching, companiesOptions }
}

export default useCompaniesListOption
