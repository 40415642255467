import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import {
	IAddActivitySectionFormProps,
	CalendarDaysType,
	CalendarDaysTypeKeys,
} from "./validation"

const ActivityDays = ({
	values,
	errors,
	touched,
	setFieldValue,
	disabled,
}: IAddActivitySectionFormProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	const selectedDays = useMemo(() => {
		return values?.calendar_working_days?.reduce(
			(acc: CalendarDaysType, day: CalendarDaysTypeKeys) => {
				acc[day] = true
				return acc
			},
			{} as CalendarDaysType,
		)
	}, [values.calendar_working_days])

	const handleDayClick = (day: CalendarDaysTypeKeys) => () => {
		if (disabled) return

		const alreadySelected = values.calendar_working_days.find((d) => d === day)
		if (alreadySelected) {
			setFieldValue(
				"calendar_working_days",
				values.calendar_working_days.filter((d) => d !== day),
			)
		} else {
			setFieldValue("calendar_working_days", [
				...values.calendar_working_days,
				day,
			])
		}
	}

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Typography className="title">{t("days_of_operation")} *</Typography>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
				}}
			>
				<Box
					className={`work-day-wrapper ${selectedDays["Monday"] && "selected"}`}
					onClick={handleDayClick("Monday")}
				>
					<Typography sx={{ color: "white" }}>{t("monday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Tuesday"] && "selected"}`}
					onClick={handleDayClick("Tuesday")}
				>
					<Typography sx={{ color: "white" }}>{t("tuesday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Wednesday"] && "selected"}`}
					onClick={handleDayClick("Wednesday")}
				>
					<Typography sx={{ color: "white" }}>{t("wednesday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Thursday"] && "selected"}`}
					onClick={handleDayClick("Thursday")}
				>
					<Typography sx={{ color: "white" }}>{t("thursday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Friday"] && "selected"}`}
					onClick={handleDayClick("Friday")}
				>
					<Typography sx={{ color: "white" }}>{t("friday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Saturday"] && "selected"}`}
					onClick={handleDayClick("Saturday")}
				>
					<Typography sx={{ color: "white" }}>{t("saturday")}</Typography>
				</Box>
				<Box
					className={`work-day-wrapper ${selectedDays["Sunday"] && "selected"}`}
					onClick={handleDayClick("Sunday")}
				>
					<Typography sx={{ color: "white" }}>{t("sunday")}</Typography>
				</Box>
			</Box>
			{touched.calendar_working_days && errors.calendar_working_days && (
				<Typography variant="caption" color="error">
					{t(errors.calendar_working_days)}
				</Typography>
			)}
		</Box>
	)
}

export default ActivityDays
