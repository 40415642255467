import * as Yup from "yup"

export interface ImportXerModalProps {
	open: boolean
	onClose: () => void
}

export type ImportXerFileFields = {
	xer_file: File | null
	company: string
}

export const ImportXerFileInitialValues: ImportXerFileFields = {
	xer_file: null,
	company: "",
}

export const ImportXerFileValidationSchema = Yup.object().shape({
	xer_file: Yup.mixed().required("errors.file_required"),
	company: Yup.string().required("errors.company_required"),
})
