import { Grid2 } from "@mui/material"
import AppButton from "../../../components/AppButton/AppButton"
import AppFormField from "../../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import {
	AddFeedbackReplyInitialValue,
	AddFeedbackReplyValidationSchema,
} from "./validation"
import { useFormik } from "formik"
import { toast } from "react-toastify"

import { useReplyToFeedbackMutation } from "../../../api/network/projectApiService"
import { useNavigate } from "react-router-dom"
import { set } from 'date-fns';
import { useState } from "react"

interface FeedbackReplyProps {
	id: string;
}

export default function FeedbackReply({ id }: FeedbackReplyProps) {
	console.log('#Id',id)
	const { t } = useTranslation("translation", { keyPrefix: "feedback" })
	const [sendReplyTrigger] = useReplyToFeedbackMutation()
	const [sendingReply, setSendingReply] = useState(false)
	const nav=useNavigate()
	const handleReplyFeedback = (values: any) => {
		setSendingReply(true)
		console.log("Feedback reply values", values)
		sendReplyTrigger({ id: id, reply: values.reply })
			.unwrap()
			.then((response) => {
				toast.success("Reply sent successfully")
				nav(-1)

			}
			)
			.catch((error) => {
				toast.error("Failed to send reply")
				console.log("Feedback reply error", error)
			}
			)
        resetForm()
	}

    const handleReplyChange = (e: any) => {
        console.log("Reply change", e.target.value)
        const len: any = e.target.value.length
        if (len > 500) {
            return toast.error("Reply length should be less than 10 characters");
        } else {
            handleChange(e)
        }
    }
  


	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			...AddFeedbackReplyInitialValue,
		},
		onSubmit: handleReplyFeedback,
		validationSchema: AddFeedbackReplyValidationSchema,
	})
	return (
		<form onSubmit={handleSubmit}>
			<Grid2 mb={1} className='title' fontFamily={'DIN bold'}>
			{t("post_reply")}
			</Grid2>
			<Grid2>
				{'Message'}
				<AppFormField
					label={""}
					name={"reply"}
					placeholder={"Please type your message here"}
					value={values.reply}
					onChange={handleReplyChange}
					onBlur={handleBlur}
                    touched={touched.reply}
					errorMsg={errors.reply ? "Message is required" : ""}
					inputProps={{
						multiline: true,
						rows: 3,
					}}
				/>
			</Grid2>
			<Grid2 mt={2}>
				<AppButton
					color="primary"
					btnSize="large"
					fullWidth={true}
					disabled={sendingReply}
					sx={{ color: "white" }}
					type="submit"
					onClick={() => {
						console.log("Post a reply clicked")
					}}
				>
					{t("send_reply")}
				</AppButton>
			</Grid2>
		</form>
	)
}
