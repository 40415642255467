import { Box, Grid2 } from "@mui/material"
import "./AddActivity.scss"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import { IAddActivitySectionFormProps } from "./validation"
import { useEffect } from "react"
import { format as dateFnsFormat } from "date-fns"


export default function ActivityDateTime({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	handleBlur,
	disabled
}: IAddActivitySectionFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	useEffect(() => {
		if (values.planned_start_date) {
			setFieldValue(
				"planned_start_date",
				dateFnsFormat(new Date(values.planned_start_date), "yyyy-MM-dd"),
			)

		}
		if(values?.planned_finish_date){
			setFieldValue(
				"planned_finish_date",
				dateFnsFormat(new Date(values.planned_finish_date), "yyyy-MM-dd"),
			)
		}
		if(values?.actual_start_date){
			setFieldValue(
				"actual_start_date",
				dateFnsFormat(new Date(values.actual_start_date), "yyyy-MM-dd"),
			)
		}
		if(values?.actual_finish_date){
			setFieldValue(
				"actual_finish_date",
				dateFnsFormat(new Date(values.actual_finish_date), "yyyy-MM-dd"),
			)
		}
		if(values?.at_completion_duration)
		{
			setFieldValue('at_completion_duration',values?.at_completion_duration)
		}
	}, [])
	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "16px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 className="title">{t("date_and_duration")}</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("planned_start_date")}
						isRequired
						type="date"
						name={"planned_start_date"}
						placeholder={t("planned_start_date")}
						disabled={disabled}
						value={values?.planned_start_date}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.planned_start_date}
						errorMsg={
							errors.planned_start_date ? t(errors.planned_start_date) : ""
						}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("planned_finished_date")}
						isRequired
						name={"planned_finish_date"}
						type="date"
						placeholder={t("planned_finished_date")}
						value={values.planned_finish_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.planned_finish_date}
						errorMsg={errors.planned_finish_date ? t(errors.planned_finish_date) : ""}
					/>
				</Grid2>
			</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("actual_start_date")}
						type="date"
						name={"actual_start_date"}
						placeholder={t("actual_start_date")}
						value={values.actual_start_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.actual_start_date}
						errorMsg={
							errors.actual_start_date ? t(errors.actual_start_date) : ""
						}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("actual_finished_date")}
						name={"actual_finish_date"}
						type="date"
						placeholder={t("actual_finished_date")}
						value={values.actual_finish_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.actual_finish_date}
						errorMsg={errors.actual_finish_date ? t(errors.actual_finish_date) : ""}
					/>
				</Grid2>
			</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("original_duration")}
						isRequired
						name={"original_duration"}
						placeholder={t("original_duration")}
						value={values.original_duration}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.original_duration}
						errorMsg={
							errors.original_duration ? t(errors.original_duration) : ""
						}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("at_completion_durations")}
						name={"at_completion_duration"}
						placeholder={t("at_completion_durations")}
						value={values?.at_completion_duration || values.at_completion_duration}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.at_completion_duration}
						errorMsg={
							errors.at_completion_duration ? t(errors.at_completion_duration) : ""
						}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}
