import { Grid2, Typography } from "@mui/material"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import { Svgs } from "../../assets/svgs"
import { routeUrls } from "../../routes/routeUrls"
import AppButton from "../../components/AppButton/AppButton"
import { useNavigate } from "react-router-dom"
import { useResendInvitationMutation } from "../../api/network/authApiSercvice"
import { useState } from "react"
import ConfirmationPopUp from "../ResetPassword/ConfirmationPopUp"

const ResendInvitation = (props: any) => {
	const { title, description, description2, backButtonText, status, email } =
		props
	const [resendInviteTrigger] = useResendInvitationMutation()
	const [InviteSent, setInviteSent] = useState(false)
    const [disableButton,setDisableButton] = useState(false)
	const nav = useNavigate()
	const handleResendInvite = () => {
        setDisableButton(true)
		console.log("Resend Invite")
		const payload = {
			email: email,
		}
		resendInviteTrigger(payload)
			.unwrap()
			.then((res) => {
				// nav(routeUrls.login)
				setInviteSent(true)
			})
			.catch((err) => {
                setDisableButton(false)
				console.log("Error", err)
			})
	}
	return (
		<>
			{InviteSent ? (
				<ConfirmationPopUp
					title="Invitation Link Sent"
					description="We have sent a renewed link for your invitation to you email. This link will expire in the next 48 hours."
					backButtonText="Go Back to Login"
					status="success"
				/>
			) : (
				<AuthLayout>
					<div className="forgot-form-container">
						<Grid2 display={"flex"} justifyContent={"left"}>
							<div
								className="logo-wrapper"
								style={{ marginTop: "5px", marginRight: "5px" }}
							>
								{status === "success" ? (
									<Svgs.CheckCircle />
								) : (
									<Svgs.WarningIcon />
								)}
							</div>
							<Typography
								className="title"
								style={{ fontSize: "22px", fontFamily: "DIN bold" }}
							>
								{" "}
								{title}{" "}
							</Typography>
						</Grid2>
						<Typography className="description"> {description} </Typography>
						<Typography className="description"> {description2} </Typography>

						<AppButton btnSize="large"
                        disabled={disableButton}
                        onClick={handleResendInvite}>
							{backButtonText}
						</AppButton>
					</div>
				</AuthLayout>
			)}
		</>
	)
}
export default ResendInvitation
