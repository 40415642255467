import { TFunction } from "i18next"
import * as Yup from "yup"
import { phoneRegex } from "../../constants/constants"

export type TProfileInformationType = "personal" | "professional"
export interface IProfileSetupFields {
	name: string
	email: string
	phone_number: string
	jobTitle: string
	company: string
	office_phone_number?: string
	onsite_emergency_contact: string
	offsite_emergency_contact_number?: string
}

export const initialProfileSetupValues: IProfileSetupFields = {
	name: "",
	email: "",
	phone_number: "",
	jobTitle: "",
	company: "",
	office_phone_number: "",
	onsite_emergency_contact: "",
	offsite_emergency_contact_number: "",
}

// export const profileSetupValidationSchema = (t: TFunction) =>
// 	Yup.object().shape({
// 		name: Yup.string().trim().required(t("")),
// 		phone_number: Yup.string().trim().required(t("error_phone_number")),
// 		company: Yup.string().trim().required(t("error_company_name")),
// 		office_phone_number: Yup.string(),
// 		onsite_emergency_contact: Yup.string().required(t("error_onsite_emergency_contact_number")),
// 		offsite_emergency_contact_number: Yup.string(),
// 	})

export const profileSetupValidationSchema = Yup.object().shape({
	name: Yup.string().trim().required("Name is required"),
	company: Yup.string().trim().required("Company name is required"),
	phone_number: Yup.string()
		.trim()
		.matches(phoneRegex, "Invalid phone number")
		.required("Phone number is required"),
	office_phone_number: Yup.string().trim().matches(phoneRegex, "Invalid phone number"),
	onsite_emergency_contact: Yup.string().trim().matches(phoneRegex, "Invalid phone number").required("Onsite emergency contact is required"),
	offsite_emergency_contact_number: Yup.string().trim().matches(phoneRegex, "Invalid phone number"),
});
