import * as Yup from "yup"
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants/constants"

export interface IForgotEmailFields {
	email: string
}

export const forgotFormInitialValues: IForgotEmailFields = {
	email: "",
}

export const forgotFormValidationSchema = Yup.object().shape({
	email: Yup.string()
		.required("errors.email_required")
		.matches(EMAIL_REGEX, "errors.invalid_email"),
})
