import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom"
import { routeUrls } from "./routeUrls"

import LoginPage from "../pages/Login/Login"
import ProfileSetupPage from "../pages/ProfileSetup/ProfileSetup"
import ResetPasswordPage from "../pages/ResetPassword/ResetPassword"
import CreatePassword from "../pages/CreatePassword/CreatePassword"
import UsersListPage from "../pages/AdminPages/Users/UsersList"
import Dashboard from "../pages/Dashboard/Dashboard"
import AddCompany from "../pages/Company/AddCompany"
import Companies from "../pages/Company/Companies"
import CompanyDetail from "../pages/Company/CompanyDetail"
import AddUser from "../pages/AdminPages/Users/AddUser"
import CreateProject from "../pages/AdminPages/Project/CreateProject"
import ProtectedRoute, { AuthorizePage } from "./ProtectedRoute"
import ProjectsList from "../pages/AdminPages/Project/Projects"
import ViewAndEditProject from "../pages/AdminPages/Project/ViewAndEditProject"
import PageLayout from "../components/PageLayout/PageLayout"
import SwimLanes from "../pages/Swimlanes/Swimlane"
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword"
import Logs from "../pages/Logs/Logs"
import Setting from "../pages/Settings/Setting"
import FeedbackList from "../pages/AdminPages/FeedbackList/FeedbackList"
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary"
import FeedbackDetails from "../pages/AdminPages/FeedbackDetails/FeedbackDetails"
import AddActivity from "../pages/Activity/AddActivity"
import PublicUserProfile from "../pages/UserProfile/PublicUserProfile"
import ActivitiesList from "../pages/Activity/ActivitiesList"
import SubActivitiesList from "../pages/Activity/SubActivitiesList"
import ActivitiesDetails from "../pages/Activity/ActivitiesDetails"
import ActivityDetailsPage from "../pages/Activity/ActivityDetailsPage"
import AddSubActivity from "../pages/SubActivity/AddSubActivity"
import SubActivityViewEdit from "../pages/SubActivity/SubActivityViewEdit"
import SwimlaneLists from "../pages/Swimlanes/SwimlaneLists"

const routes: RouteObject[] = [
	{
		path: routeUrls.root,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: routeUrls.root,
				element: <Navigate to={routeUrls.login} />,
			},
			{
				path: routeUrls.login,
				element: <LoginPage />,
			},
			{
				path: routeUrls.profileSetup,
				element: <ProfileSetupPage />,
			},
			{
				path: routeUrls.resetPassword,
				element: <ResetPasswordPage />,
			},
			{
				path: routeUrls.forgotPassword,
				element: <ForgotPassword />,
			},
			{
				path: routeUrls.signup,
				element: <CreatePassword />,
			},
			{
				element: <ProtectedRoute />,
				children: [
					{
						element: <PageLayout />,
						children: [
							{
								path: routeUrls.users.list,
								element: (
									<AuthorizePage
										path={routeUrls.users.list}
										element={<UsersListPage />}
									/>
								),
							},
							{
								path: routeUrls.projects.list,
								element: (
									<AuthorizePage
										path={routeUrls.projects.list}
										element={<ProjectsList />}
									/>
								),
							},
							{
								path: routeUrls.projects.create,
								element: (
									<AuthorizePage
										path={routeUrls.projects.create}
										element={<CreateProject />}
									/>
								),
							},
							{
								path: routeUrls.projects.viewAndEdit(),
								element: (
									<AuthorizePage
										path={routeUrls.projects.viewAndEdit()}
										element={<ViewAndEditProject />}
									/>
								),
							},
							{
								path: routeUrls.users.add,
								element: (
									<AuthorizePage
										path={routeUrls.users.add}
										element={<AddUser />}
									/>
								),
							},
							{
								path: routeUrls.users.profile(),
								element: (
									<AuthorizePage
										path={routeUrls.users.profile()}
										element={<PublicUserProfile />}
									/>
								),
							},
							{
								path: routeUrls.dashboard,
								element: <Dashboard />,
							},
							{
								path: routeUrls.dashboardChild.company,
								element: (
									<AuthorizePage
										path={routeUrls.dashboardChild.company}
										element={<Companies />}
									/>
								),
							},
							{
								path: routeUrls.dashboardChild.addCompany,
								element: (
									<AuthorizePage
										path={routeUrls.dashboardChild.addCompany}
										element={<AddCompany />}
									/>
								),
							},
							{
								path: routeUrls.dashboardChild.company + "/:companyId",
								element: (
									<AuthorizePage
										path={routeUrls.dashboardChild.company + "/:companyId"}
										element={<CompanyDetail />}
									/>
								),
							},
							{
								path: routeUrls.myCompany,
								element: <CompanyDetail isMyCompany />,
							},
							{
								path: routeUrls.feedback_list,
								element: <FeedbackList />,
							},
							{
								path: routeUrls.swimlane_list,
								element: <SwimlaneLists />,
							},
							{
								path: routeUrls.logs,
								element: <Logs />,
							},
							{
								path: routeUrls.feedback_detail,
								element: <FeedbackDetails />,
							},
							{
								path: routeUrls.activity.addActivity,
								element: <AddActivity />,
							},
							{
								path: routeUrls.subActivity.addSubActivity,
								element: <AddSubActivity />,
							},
							{
								path:routeUrls.subActivity.viewSubActivityDetails(),
								element: <SubActivityViewEdit />,
							},
							{
								path: routeUrls.activity.activityDetails(),
								element: <ActivityDetailsPage />,
							},
							{
								path: routeUrls.profile,
								element: <Setting />,
							},
							{
								path: routeUrls.activity.list,
								element: (
									<AuthorizePage
										path={routeUrls.activity.list}
										element={<ActivitiesList />}
									/>
								),
							},
							// {
							// 	path: routeUrls.subActivity.list,
							// 	element: (
							// 		<AuthorizePage
							// 			path={routeUrls.subActivity.list}
							// 			element={<SubActivitiesList />}
							// 		/>
							// 	),
							// },
						],
					},
					{
						path: routeUrls.swimlane,
						element: <SwimLanes />,
					},
				],
			},
		],
	},
]

export default createBrowserRouter(routes)
