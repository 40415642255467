import * as Yup from "yup"
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants/constants"

export interface ILoginFields {
	email: string
	password: string
}

export const loginFormInitialValues: ILoginFields = {
	email: "",
	password: "",
}

export const loginValidationSchema = Yup.object().shape({
	email: Yup.string()
		.required("errors.email_required")
		.matches(EMAIL_REGEX, "errors.invalid_email"),
	password: Yup.string()
		.required("errors.password_required")
		.test("complexity", "errors.invalid_password", (value) => {
			if (!value) return false // Don't validate if value is empty (handled by required)
			// return PASSWORD_REGEX.test(value)
			return true
		}),
})
