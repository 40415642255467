import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { RESPONSE_CODE } from "../../constants/constants"
import { toast } from "react-toastify"
import { logoutUser } from "../../utils/authUtils"

export const handleAuthError = (error: FetchBaseQueryError) => {
	console.log("error captured", error)
	if (error.status === RESPONSE_CODE.BAD_REQUEST) {
		const data: any = error.data
		if (data?.error) {
			toast.error(data.error)
		}
	} else if (error.status === RESPONSE_CODE.UNAUTHORIZED) {
		const data: any = error.data
		if (data?.detail) {
			toast.error(data.detail)
		} else {
			toast.error("Session expired.")
		}
		// logging out user incase of UnAuthorized error
		logoutUser()
	}
}
