import { Box, Modal, Typography } from "@mui/material"
// import "./AppSidebar.scss"
import { useTranslation } from "react-i18next"
import AppButton from "../AppButton/AppButton"

interface AppConfirmationModalProps {
	open: boolean
	onConfirm: () => void
	onClose?: () => void
	isLogoutLoading?: boolean
    confirmationMessage?: string
	closeButtonText?: string
	confirmButtonText?: string
}

const AppConfirmationModal = ({
	open,
	onConfirm,
	onClose,
	isLogoutLoading,
    confirmationMessage,
	closeButtonText,
	confirmButtonText,
}: AppConfirmationModalProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "common.sideNavbar",
	})

	return (
		<Modal open={open}>
			<div className="logout-confirm-modal-container">
				<Typography variant="h5" color="primary">
					{confirmationMessage || "No confirmation message"}
				</Typography>
				<Box
					mt="30px"
					mb="10px"
					display="flex"
					gap="16px"
					justifyContent="flex-end"
				>
					<AppButton
						onClick={onClose}
						btnSize="medium"
						variant="outlined"
						disabled={isLogoutLoading}
					>
						{closeButtonText? closeButtonText: t("no")}
					</AppButton>
					<AppButton
						onClick={onConfirm}
						btnSize="medium"
						disabled={isLogoutLoading}
					>
						{confirmButtonText? confirmButtonText: t("yes")}
					</AppButton>
				</Box>
			</div>
		</Modal>
	)
}

export default AppConfirmationModal
