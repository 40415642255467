import { Dispatch } from "redux"
import router from "../routes/router"
import { routeUrls } from "../routes/routeUrls"
import { localStorage } from "./localstorage"
import { AuthAPIs } from "../api/network/authApiSercvice"
import { CompanyAPIs } from "../api/network/companyApiService"
import { ProjectAPIs } from "../api/network/projectApiService"
import { RolesAPIs } from "../api/network/rolesApiService"
import { UserAPIs } from "../api/network/userApiService"
import { clearAuthData } from "../api/slices/AuthSlice"
import { clearUserProjectData } from "../api/slices/UserProjectSlice"

export const clearAllApiCache = (dispatch: Dispatch) => {
	// clear all API caches
	dispatch(AuthAPIs.util.resetApiState())
	dispatch(CompanyAPIs.util.resetApiState())
	dispatch(ProjectAPIs.util.resetApiState())
	dispatch(RolesAPIs.util.resetApiState())
	dispatch(UserAPIs.util.resetApiState())
	dispatch(clearAuthData())
	dispatch(clearUserProjectData())
}

export const logoutUser = () => {
	localStorage.removeUserData()
	router.navigate(routeUrls.login)
}
